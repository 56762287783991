
export const attributeTypes = [
    {
        id: "bool",
        name: "True | False",
        value:"true or false"
    },
    {
        id: "number",
        name: "Number",
        value:"numeric value",
        constraint: [
            {
                id: "positive",
                name: "Positive (>0)",
            },
            {
                id: "negative",
                name: "Negative (<0)"
            },
        ]
    },
    {
        id: "text",
        name: "Text",   
        value: "textual value",
    },
    {
        id: "date",
        name: "Date",
    },
    {
        id: "time",
        name: "Time",
    },
    {
        id: "datetime",
        name: "Date Time",
    },
    {
        id: "url",
        name: "URL",
    },
    {
        id: "did",
        name: "DID",
    },                        
]

export function getAttributeName(id = "text") {
    const attr = attributeTypes.find(attr => attr.id === id);

    return attr?.value || attr?.name || id || ""
}