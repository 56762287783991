import { claimJsonConverter } from "./claim";
import { entityJsonConverter } from "./entity";

export class VcTypeDocument {
    uri() {
        return this.vocab + this.term;
    }

    toString() {
        return (this.verb ? this.verb + " " : "") + (this.name || (this.object.type === "entity" && this.object.entity.toString()) || this.term);
    }
}


function convertJsonClaims(claims) {

    const result = [];

    for (const claim of claims) {
        const childs = claim.claims ? convertJsonClaims(claim.claims) : [];

        const { modified, ...data } = claim;

        result.push(claimJsonConverter.fromJson({
            ...data,
            modified: modified ? new Date(modified) : null,
            claims: childs,
        }))
    }
    return result;

}

export const vcTypeJsonConverter = {
    fromJson: ({ subject, claims, ...rest }) => {

        return Object.assign(new VcTypeDocument(), {
            subject: subject ? entityJsonConverter.fromJson(subject) : null,
            claims: claims ? convertJsonClaims(claims) : [],
            ...rest,
        })
    },
}