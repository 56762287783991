import { Info, Public, TrendingDown, TrendingFlat, TrendingUp, Warning } from "@mui/icons-material";
import { alpha, Badge, Box, Button, Card, CardActionArea, CardActions, CardContent, Checkbox, Stack, Tooltip, Typography } from "@mui/material";
import { amber, green, grey, lightBlue, lime, red } from "@mui/material/colors";
import { useState } from "react";

const VARIANTS = {
    blue: {
        top: {
            bgcolor: lime[700],
            borderColor: lime[800],
            bgcolor: lightBlue[600],
            borderColor: lightBlue[500],
            borderWidth: 1,
            borderStyle: "solid",
            color: "white",
            borderRadius: 2,
        },
        action: {
            bgcolor: lightBlue[800],
        },
        text: {
            primary: {
                color: "white",
            },
            secondary: {
                color: grey[100],
            }
        }
    },
    outlined: {
        top: {
            borderColor: lime[400],
            borderWidth: 1,
            borderStyle: "solid",
            color: "black",
            borderRadius: 2,
        },
        action: {
            bgcolor: lime[200],
        },
        text: {
            primary: {
                color: "black",
            },
            secondary: {
                color: grey[700],
            }
        }
    },
    simple: {
        top: {
            borderColor: lime[200],
            borderWidth: 1,
            borderStyle: "solid",
            color: "black",
            borderRadius: 2,
        },
        action: {
            bgcolor: lime[100],
        },
        text: {
            primary: {
                color: "black",
                fontSize: 18,
                fontWeight: 500,
            },
            secondary: {
                color: grey[700],
            }
        }
    }
}

export function CardView({
    usage = 0,
    onClick,
    title,
    subtitle,
    description,
    uri,
    updateAvaiable = false,
    variant = "blue",
    badges = true,
    index = false,
}) {

    const [trend] = useState(Math.floor(Math.random() * 3));

    return (
        <Card
            elevation={0}
            sx={{
                ...VARIANTS[variant].top,
            }}
        >
            <CardActionArea
                onClick={onClick}
                sx={{
                    display: 'flex',
                    justifyContent: "space-between",
                    alignItems: "center",
                    "&:hover": {
                        ...VARIANTS[variant].action,
                    },
                    "&.MuiCardActionArea-focusHighlight:hover": {
                        ...VARIANTS[variant].action,

                    },
                    "&.Mui-focusVisible": {
                        ...VARIANTS[variant].action,
                    }
                }}
            >

                <CardContent 
                sx={{
                    p: index ? 0 : t => t.spacing(1, 1, 1, 2),
                }}
                >
                    <Stack direction="row"  alignItems="stretch">
                        {(index) && (
                            <Box 
                                display="flex"
                                alignItems="center"
                                textAlign="center"
                                sx={{
                                    // borderColor: amber[50],
                                    // borderRightStyle: "solid",
                                    bgcolor: lime[100],
                                }}
                            >
                                <Box sx={{
                                    p: t => t.spacing(0, 1.5, 0, 2),
                                    fontWeight: 500,
                                    fontSize: 14,
                                    letterSpacing: 0.2,
                                }}>
                                    {index}
                                </Box>
                            </Box>
                        )}
                        <Box 
                                        sx={{
                                            p: index ? t => t.spacing(1, 1, 1, 2) : 0,
                                        }}
                        >
                            <Typography
                                sx={{
                                    fontSize: 20,
                                    fontWeight: 600,
                                    fontFamily: 'Montserrat, sans-serif',
                                    ...VARIANTS[variant].text.primary,
                                }}
                                component="div"
                            >
                                {title}
                            </Typography>
                            {subtitle && (
                                <Typography sx={{ textTransform: "uppercase", fontSize: 13, ...VARIANTS[variant].text.secondary, }}>
                                    {subtitle}
                                </Typography>
                            )}
                            {description && (
                                <Typography variant="body2" sx={{ mt: 1 }}>
                                    {description}
                                </Typography>
                            )}
                            {uri && (
                                <Typography sx={{ fontSize: 12, mt: .75, ...VARIANTS[variant].text.secondary, }}>
                                    {uri}
                                </Typography>
                            )}
                        </Box>
                    </Stack>
                </CardContent>
                {badges && (
                    <Stack
                        direction="column"
                        justifyContent="space-between"
                        alignItems="flex-end"
                        spacing={2}
                        // justifySelf="end"
                        alignSelf="stretch"
                        sx={{
                            mr: 2,
                            mb: 2,
                            mt: 2,
                        }}
                    >
                        <Box
                            sx={{
                                // bgcolor: "#fafaff",
                                p: theme => theme.spacing(.5, 1, 1, 1),
                                fontSize: 18,
                            }}
                        >
                            <Tooltip title="Public">
                                <Public />
                            </Tooltip>
                        </Box>
                        <Box
                            sx={{
                                // bgcolor: "#fafaff",
                                p: theme => theme.spacing(.5, 1, 1, 1),
                                borderRadius: 2,
                                fontSize: 20,
                                borderStyle: "none",
                                bgcolor: lightBlue[600],
                                bgcolor: trend === 0 ? green[300] : (trend === 1 ? red[300] : "inherit"),
                            }}
                        >
                            <Tooltip title="Trend & Usage">
                                <Badge badgeContent={usage} showZero
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                >
                                    {trend === 0 && (<TrendingUp size="small" />)}
                                    {trend === 1 && (<TrendingDown size="small" />)}
                                    {trend === 2 && (<TrendingFlat size="small" />)}
                                </Badge>
                            </Tooltip>
                        </Box>
                    </Stack>
                )}
            </CardActionArea>
            {updateAvaiable && (
                <CardActions sx={{ pl: 2, backgroundColor: "white" }}>
                    <Box sx={{
                        fontSize: 14,
                        color: theme => theme.palette.info.dark,

                    }} display="flex" alignItems="center">
                        <Info size="small" sx={{ mr: 1 }} /> There is a newer version
                        <Button disabled>Compare & Update</Button>

                    </Box>
                </CardActions>
            )}
        </Card>);
}

export function CardViewStub({ title, subtitle, description, uri }) {

    return (
        <Card
            elevation={0}
            sx={{
                // bgcolor: amber[400],
                borderColor: amber[400],
                bgcolor: alpha(amber[50], 0.25),
                // borderColor: lightBlue[500],
                borderWidth: 1,
                borderRadius: 2,
                borderStyle: "solid",
            }}
        >
            <CardContent sx={{
                borderBottomColor: amber[400],
                borderBottomStyle: "solid",
                borderBottomWidth: 1,
            }}>
                <Typography
                    sx={{
                        fontSize: 20,
                        fontWeight: 600,
                        fontFamily: 'Montserrat, sans-serif',
                    }}
                    component="div"
                >
                    {title}
                </Typography>
                {subtitle && (
                    <Typography sx={{ textTransform: "uppercase", fontSize: 13 }}>
                        {subtitle}
                    </Typography>
                )}
                <Typography variant="body2" sx={{ mt: 1 }}>
                    {description}
                </Typography>
                <Typography sx={{ fontSize: 12, mt: .75 }}>
                    {uri}
                </Typography>
            </CardContent>
            <CardActions sx={{ pl: 2, backgroundColor: "white" }}>
                <Box sx={{
                    fontSize: 14,
                    color: theme => theme.palette.warning.dark,
                }} display="flex" alignItems="center">
                    <Warning size="small" sx={{ mr: 1 }} /> This is a stub.
                    <Button disabled>Clone</Button>
                    and add the definition to a directory.
                </Box>
            </CardActions>
        </Card >);
}

export function SelectableCardView({ selected, onClick, title, subtitle, description, uri, }) {

    return (
        <Box
            sx={{
                // borderColor: grey[400],
                // borderWidth: 1,
                // backgroundColor: grey[50],
                // borderTopStyle: "solid",
                color: "black",
                // marginBottom: 1,
                display: "flex",
                alignItems: "center",
            }}
        >
            <Box flexGrow={0} m={t => t.spacing(0, 2, 0, 2)}>
                <Checkbox
                    checked={selected}
                    // value={value}
                    color="secondary"
                    onChange={e => onClick(e.target.value)}
                />
            </Box>
            <Box flexGrow={1} sx={{
                m: t => t.spacing(1, 1, 1, 1),
            }}>
                <Typography
                    sx={{
                        fontSize: 18,
                        // fontWeight: 600,
                        fontFamily: 'Montserrat, sans-serif',
                        p: 0,
                    }}
                    component="div"
                    color="black"
                >
                    {title}
                </Typography>
                {subtitle && (
                    <Typography sx={{ textTransform: "uppercase", fontSize: 13 }} color="text.secondary">
                        {subtitle}
                    </Typography>
                )}
                <Typography variant="body2" sx={{ mt: .5, fontFamily: 'Montserrat, sans-serif', }}>
                    {description}
                </Typography>
                <Typography sx={{ fontSize: 12, mt: .5 }} color="text.secondary">
                    {uri}
                </Typography>
            </Box>
        </Box>);
}