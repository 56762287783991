
export const credentialDomains = [
    {
        id: "education",
        name: "Education",
    },
    {
        id: "retail",
        name: "Retail",
    },
    {
        id: "finance",
        name: "Finance",
    },
    {
        id: "healthcare",
        name: "Healthcare",
    },
    {
        id: "legalid",
        name: "Legal Identity",
    },
    {
        id: "procredentials",
        name: "Professional Credentials",
    },
    {
        id: "devices",
        name: "Devices",
    },
    {
        id: "personal",
        name: "Personal",
    },    
    {
        id: "hobby",
        name: "Hobby",
    },        
    {
        id: "commerce",
        name: "Commerce",
    },            
    {
        id: "business",
        name: "Business",
    },                
];
