import { FormControl, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material';
import { FormPanel } from 'component/element/Layout';
import * as yup from 'yup';


StepEnglishName.Schema = yup.object().shape({

  article: yup.string().trim().optional().nullable(),

  name: yup.string()
    .trim()
    .required()
    .matches(/^[a-zA-Z ]+$/, 'only letters and spaces allowed')
    .min(4)
});

export function StepEnglishName({
  defaultValue: { article: defaultArticle, name: defaultName } = { article: "na", name: "" },
  error = false,
  onSubmit = () => { },
  onChange = () => { }
}) {

  const handleArticleChange = (e) => {
    let article = e.target.value.trim();

    if (article === "na") {
      article = null;
    }

    onChange({ article });
  }

  return (
    <FormPanel>
      <Stack
        direction="row"
        spacing={2}
      >
        <FormControl
          sx={{ width: 80, }}
        >
          <InputLabel
            id="article-select-label"
            sx={{ p: 0, m: 0, width: 140, left: -16, top: 6 }}
          >
            Article
          </InputLabel>

          <Select
            labelId="article-select-label"
            id="article-select"
            defaultValue={defaultArticle || "na"}
            label="Article"
            variant='standard'
            autoFocus
            name="article"
            onChange={handleArticleChange}
          >
            <MenuItem value="na">n/a</MenuItem>
            <MenuItem value="a">a</MenuItem>
            <MenuItem value="an">an</MenuItem>
          </Select>
        </FormControl>

        <TextField
          defaultValue={defaultName || ""}
          fullWidth
          label="Name"
          name="name"
          variant='standard'
          error={!!error?.name}
          helperText={error?.name || ""}
          onBlur={e => onChange({ name: e.target.value.trim() })}
          onKeyPress={(ev) => {
            if (ev.key === 'Enter') {
              ev.preventDefault();
              onSubmit({ name: ev.target.value.trim() });
            }
          }}
        />
      </Stack>
    </FormPanel>
  );
}
