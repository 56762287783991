import { Check, MoodBad } from '@mui/icons-material';
import { Icon, Typography } from '@mui/material';
import Designer from 'component/element/Designer';
import { deleteDraft } from 'component/element/Drafts';
import { StepName } from 'component/step/StepName';
import { StepSubject, StepSubjectDetail } from 'component/step/StepSubject';
import { designConverter } from 'document/design';
import { VcTypeDocument, vcTypeJsonConverter } from 'document/vcType';
import { createCredentialType } from 'document/vcTypeService';
import { useApp } from 'lib/app';
import { useNavigate, useParams } from 'react-router-dom';
import { StepDescription } from '../step/StepDescription';
import { StepDomain } from '../step/StepDomain';
import { CamelIdSchema, StepId } from '../step/StepId';
import CredentialTypePreview from './CredentialTypePreview';
import CredentialTypeStart from './CredentialTypeStart';
import StepClaims from './step/StepClaims';
import StepCredentialTypeReview from './step/StepCredentialTypeReview';

const steps = [
  {
    id: "domain",
    control: StepDomain,
    label: "Domain",
    description: "Please, select one or more domains the credential type falls in",
  },
  {
    id: "subject",
    control: StepSubject,
    label: "Subject",
    description: "Select a subject type the credential is issued to",
    detail: {
      label: "Subject Details",
      Component: StepSubjectDetail,
    },
  },
  {
    id: "name",
    control: StepName,
    label: "Name",
    description:
      `A name should be a simple sentence composed of a few words 
       helping others to understand what claims about someone 
       or something the Verifiable Credential type certifies.`,
    examples: [
      (<>
        Over Age Credential<Icon color="success"><Check /></Icon><br />
        Employee Identification Badge <Icon color="success"><Check /></Icon><br />
        Natural Gas Power Plants Carbon Dioxide Emission Compliance <Icon color="success"><Check /></Icon><br />
        Age 21 Verification <Icon color="error"><MoodBad /></Icon><br />
        Employee Record <Icon color="error"><MoodBad /></Icon><br />
      </>)
    ]
  },
  {
    id: "term",
    key: ["term", "vocab"],
    control: StepId,
    label: "Identifier",
    schema: CamelIdSchema,
    param: {
      syntax: "camel",
    },
    description:
      `An ID is used by machines to uniqly idenfity the credential type. `,
    examples: [
      (<>
        OverAge <Icon color="success"><Check /></Icon><br />
        EmployeeIdBadge <Icon color="success"><Check /></Icon><br />
        Over_21_Age_Verification <Icon color="error"><MoodBad /></Icon><br />
        Employee-2022 Badge <Icon color="error"><MoodBad /></Icon><br />
      </>)
    ],
    help:
      `An ID must start with a capital letter and can contain only letters and digits. 
       It is recommended to compose the Id from words describing the credential 
       purpose and start each word with a capital letter.`
  },

  {
    id: "claims",
    control: StepClaims,
    label: "Claims",
    description:
      `A claim is a statement about the credential subject or/and entities related to the subject.`,
    examples: [
      (<>
        To prove that some is eligible to buy an alchol in the US you can define a claim type overAge with a value 21 instead of revealing date of birth.
        The date of birth is well-know to an issuer issuing the credential. A verifier does not need to know the exact age.
      </>)
    ],
    help: (<>
      <Typography m={1} mb={2}
        sx={{
          fontFamily: 'Montserrat, sans-serif',
        }}
      >
        A claim may include associated evidence, results, or other metadata regarding a specific achievement,
        skill or assertion.
      </Typography>

      <Typography m={1} mb={2} sx={{ textAlign: "center", }}>
        <b>a subject</b> has/is <b>claim name</b> of <b>claim value type</b>
      </Typography>

      <Typography m={1} mb={2} sx={{
        fontFamily: 'Montserrat, sans-serif',
      }}>
        Please note that an issuer is responsible for the statement genuinity, i.e. a claim value.
      </Typography>

      <Typography m={1} mb={3} sx={{
        fontFamily: 'Montserrat, sans-serif',
      }}>
        Define minimum viable set of claims per a credential type. Try to avoid huge lists
        as it helps to minimalize misuse, privacy and security concenrs. A claim should state only the minimal information needed for verification.
      </Typography>
    </>)
  },
  {
    id: "description",
    control: StepDescription,
    label: "Description",
    description:
      `Please describe what the new verifiable credential type proves and how the credential should be used.`,
    help:
      `The description helps to discover and understand specifics about the credential type.`,
  },
  // { id: "review", label: "Review" }
];

export const VC_DRAFT_KEY_PREFIX = "vc-draft-";

export const VC_DRAFT_DEF = {
  prefix: VC_DRAFT_KEY_PREFIX,
  toUrl: (id) => `/credential-type/draft/${id}`,
  label: (draft) => `credential type`.trim(),
  converter: designConverter(vcTypeJsonConverter),
}

export default function CredentialTypeDesigner() {

  const { draftId } = useParams();

  const { progress, notify } = useApp()

  const navigage = useNavigate();

  const handleComplete = (data) => {
    progress(true);
    createCredentialType(data)
      .then((ref) => {
        notify("A new credential type has been created.");
        deleteDraft(VC_DRAFT_KEY_PREFIX + draftId);
        navigage(`/credential-type/${ref.id}`);
      })
      .catch((e) => notify(e, "error"))
      .then(() => progress(false))
  }

  return (
    <Designer
      title="New Verifiable Credential Type"
      subtitle="A credential type is a template used to issue and to present a verifiable credential"

      draftId={draftId ? VC_DRAFT_KEY_PREFIX + draftId : null}
      draftDef={VC_DRAFT_DEF}

      steps={steps}

      defaultData={new VcTypeDocument()}

      DocumentType={VcTypeDocument}

      StartComponent={CredentialTypeStart}
      EndComponent={StepCredentialTypeReview}
      PreviewComponent={CredentialTypePreview}

      onComplete={handleComplete}
    />
  );
}
