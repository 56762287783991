import { Add, Delete, ExpandMore, KeyboardDoubleArrowDown } from "@mui/icons-material";
import { Box, Button, Card, CardActionArea, Checkbox, Collapse, Divider, Fade, FormControlLabel, Icon, Typography } from "@mui/material";
import { amber, grey, lightBlue, red } from "@mui/material/colors";
import { ClaimDocument } from "document/claim";
import { useState } from "react";
import * as yup from 'yup';
import { ClaimsSelector } from "./ClaimsSelector";

StepClaims.Schema = yup.array().typeError("Add at least one claim").min(1).required();

StepClaims.Controlled = true;

function flattenClaims(subject, claims = [], nodeMap = [], update, index = 0, depth = 0) {

    nodeMap.push({ subject, index, claims, update, depth });

    claims && claims
        .filter(claim => claim.object.type === "entity")
        .forEach(claim => {
            claim.object.entity.index = nodeMap.length
            flattenClaims(
                claim.object.entity,
                claim.claims,
                nodeMap,
                (value) => claim.claims = value,
                claim.object.entity.index,
                depth + 1,
            );
        });

    return nodeMap;
}

function Claim({ value, onRemove, onChange }) {

    const [expanded, setExpanded] = useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const handleRemove = () => {
        onRemove();
    }

    const handleMandatoryChange = (event) => {
        onChange({ mandatory: event.target.checked })
    }

    let title = value.toString();
    let subtitle = null;
    let suffix = null;
    let entity = null;

    if (value.object.type === "entity") {
        entity = value.object.toString();
        suffix = ` #${value.object.entity.index}`;
        subtitle = "relation"
    } else {
        subtitle = (value.mandatory ? "mandatory " : "") + value.object.toString().replace("value", "attribute");
    }

    return (
        <Card variant="outlined" sx={{ m: 0, pl: .5, pb: 0, mb: 0, mr: 0, }} elevation={0}>
            <CardActionArea onClick={handleExpandClick}>
                <Box display="flex" justifyContent="space-between" alignItems="center"
                    sx={{
                        m: t => t.spacing(1.5, 1, 1.5, 2),
                    }}
                >
                    <Box>
                        <Box
                            sx={{
                                fontSize: 18,
                                p: 0,
                                alignItems: "baseline",
                                display: "flex",
                                pr: 0.5,
                            }}
                        >
                            <Box sx={{
                                fontFamily: 'Montserrat, sans-serif',
                            }}>

                                {title}


                            </Box>
                            {suffix && (
                                <Box sx={{
                                    // fontSize: 16,
                                    ml: 1,
                                    display: "flex",
                                    alignItems: "center",
                                    fontWeight: 500,
                                }}>
                                    <Box sx={{
                                        fontFamily: 'Montserrat, sans-serif',

                                    }}>
                                        {entity}

                                    </Box>
                                    <Box ml={1} sx={{
                                        fontSize: 15,
                                    }}>
                                        {suffix}
                                    </Box>
                                </Box>
                            )}
                        </Box>
                        {subtitle && (
                            <Typography sx={{ textTransform: "uppercase", fontSize: 13 }} color="text.secondary">
                                {subtitle}
                            </Typography>
                        )}
                    </Box>
                    <Box pr={1}>
                        {/* <ExpandMoreButton
                            expand={expanded}
                            onClick={handleExpandClick}
                            aria-expanded={expanded}
                            aria-label="show more"
                        > */}
                        <ExpandMore
                            sx={{
                                transform: !expanded ? 'rotate(0deg)' : 'rotate(180deg)',
                                marginLeft: 'auto',
                                transition: theme => theme.transitions.create('transform', {
                                    duration: theme.transitions.duration.shortest,
                                }),
                            }}
                        />
                        {/* </ExpandMoreButton> */}
                    </Box>

                    {/* action={

                    }
                    //sx={{ mb: 0, pb: 0 }}
                    title={title + (value.mandatory ? ` *` : "")}
                    titleTypographyProps={{
                        fontSize: 18,
                        fontFamily: 'Montserrat, sans-serif',
                    }}
                    subheaderTypographyProps={{
                        fontSize: 15,
                        fontFamily: 'Montserrat, sans-serif',
                        color: "text.secondary",
                    }}
                    subheader={value.description}
                    sx={{
                        pb: 1,
                    }}
                 */}
                </Box>
            </CardActionArea>
            {/* <Collapse in={expanded} timeout="auto" unmountOnExit mountOnEnter> */}
            {expanded && (
                <Box sx={{ pl: 2, pr: 2, pt: 0, mt: 0, mb: 1 }}>
                    <Typography variant="body2" sx={{ mt: 1, fontFamily: 'Montserrat, sans-serif', }}>
                        {value.description}
                    </Typography>

                    <Typography sx={{
                        fontSize: 14,
                        //  ml: 1,
                    }}
                        color="text.secondary"
                    >
                        {value.uri()}
                    </Typography>
                    <Box display="flex" alignItems="center" mt={0.5}>
                        <FormControlLabel
                            sx={{ ml: 0 }}
                            size="small"
                            variant="standard"
                            control={<Checkbox checked={value.mandatory || false} size="small"
                            // onChange={e => {
                            //     onChange && onChange({ ...value, required: e.target.value });
                            // }}
                            />}
                            label="Mandatory"
                            disabled={value.object.isRel()}
                            onChange={handleMandatoryChange}
                        />

                        {/* <FormControl variant="standard">
                            <InputLabel id="demo-simple-select-label">Cardinality</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                defaultValue={10}
                                label="Cardinality"
                            //    onChange={handleChange}
                            >
                                <MenuItem value={10}>a single value</MenuItem>
                                <MenuItem value={20}>an array of values </MenuItem>
                            </Select>
                        </FormControl> */}
                        {/* <Typography>
                        {getDataTypeLabel(value.dataType)}
                    </Typography> */}
                        <Box flexGrow={1}></Box>
                        <Button
                            disableElevation
                            aria-label="remove"
                            onClick={handleRemove}
                            color="error"
                            startIcon={<Delete />}
                            size="small"

                        >
                            Remove
                        </Button>
                    </Box>
                </Box>
            )}
            {/* </Collapse> */}
        </Card>

    );
}

function ClaimSet({ value, onChange, error, first, }) {

    const [mode, setMode] = useState("view");

    const [expanded, setExpanded] = useState(true);

    const { claims = [], subject, index, update, depth } = value;

    const handleAddClaims = (claimsToAdd) => {
        setMode("view");
        if (claims === null) {
            update(claimsToAdd);
            onChange(claimsToAdd);
            return;
        }
        update([...claims, ...claimsToAdd]);
        onChange([...claims, ...claimsToAdd]);
    };

    const handleRemoveClaim = (index) => () => {
        const newClaims = claims.filter((_, i) => i !== index);
        update(newClaims);
        setMode("view");
        onChange(newClaims);
    }

    const handleChangeClaim = (index) => (value) => {
        const newClaims = [...claims];
        newClaims[index] = Object.assign(new ClaimDocument(), { ...claims[index], ...value });
        update(newClaims);
        onChange(newClaims);
    }

    return (
        <Box>
            <Box
                sx={{
                    backgroundColor: "#fff",
                    color: "#000",
                    borderRadius: "5px",
                }}
            >
                <Box sx={{
                    backgroundColor: lightBlue[500],
                }}>
                    <ClaimSetHeader
                        subject={subject}
                        index={index}
                        depth={depth}
                        expanded={expanded}
                        onChange={setExpanded}
                    />
                </Box>
                {expanded && (<>
                    {claims && claims.map((claim, i) => (
                        <Claim
                            key={claim.ref.path}
                            value={claim}
                            onRemove={handleRemoveClaim(i)}
                            onChange={handleChangeClaim(i)}
                        />
                    ))}

                    <Fade in={mode === "view"} mountOnEnter unmountOnExit
                        timeout={{ enter: 500, exit: 0 }}
                    >
                        <Box p={theme => theme.spacing(1, 1, 1, 2)}>
                            <Button
                                startIcon={<Add />}
                                onClick={() => setMode("add")}
                                color="secondary"
                            >
                                Add Claim
                            </Button>
                        </Box>
                    </Fade>

                    <Fade in={mode === "view" && error && depth === 0 && index === 0} mountOnEnter unmountOnExit
                        timeout={{ enter: 500, exit: 0 }}
                    >
                        <Box p={theme => theme.spacing(0, 2, 2, 2)}
                            
                            sx={{
                                fontSize: 14,
                                color: red[400],
                            }}>
                            {error}
                        </Box>
                    </Fade>

                    <Collapse in={mode === "add"} mountOnEnter unmountOnExit>
                        <Box>
                            <ClaimsSelector
                                subject={subject}
                                onCancel={() => setMode("view")}
                                onAdd={handleAddClaims}
                                selected={claims && claims.map(claim => claim.ref.path)}
                            />
                        </Box>
                    </Collapse>
                </>)}
            </Box>
        </Box>
    );
}

function Claims({ value, onChange, error = false }) {

    const handleClaimSetChange = (index) => (newClaims) => {
        onChange(index === 0 ? newClaims : null);
    };

    return (<>
        {value.map((group, index) => (
            <Box
                key={index}
                sx={{
                    borderRadius: "5px",
                    marginLeft: theme => theme.spacing(group.depth * 2),

                }}
            >
                {index > 0 && (
                    <Divider
                        textAlign="left"

                        sx={{
                            "&::before": {
                                borderColor: lightBlue[200],
                                width: `${100 - 10 * (group.depth)}%`,
                            },
                            "&::after": {
                                borderColor: lightBlue[200],
                                width: `${10 * (group.depth)}%`,
                            },
                            // left: 100,
                            // position: "relative",
                            // overflow: "hidden",
                        }}>
                        <Icon
                            sx={{
                                color: lightBlue[100],
                            }}

                        >
                            <KeyboardDoubleArrowDown />
                        </Icon>
                    </Divider>
                )}
                <Box sx={{

                }}>
                    <ClaimSet
                        value={group}
                        onChange={handleClaimSetChange(index)}
                        first={index === 0}
                        error={error}
                    />
                </Box>
            </Box>
        ))}
    </>);
}

export default function StepClaims({
    defaultValue,
    data: { subject },
    error = false,
    onChange = () => { },
}) {

    const [claims, setClaims] = useState(defaultValue)

    const handleChange = (newClaims) => {

        // hack
        if (!newClaims) {
            setClaims([...claims]);
            onChange([...claims]);
            return;
        }
        setClaims(newClaims);
        onChange(newClaims);
    }

    return (
        <Claims
            value={flattenClaims(subject, claims, [], (value) => { })}
            onChange={handleChange}
            error={error}
        />
    );
}

function ClaimSetHeader({ subject, expanded = true, onChange = () => { }, index = null, depth = 0 }) {
    return (
        <CardActionArea
            sx={{
                fontSize: 20,
                fontFamily: 'Montserrat, sans-serif',
                backgroundColor: `${index > 0 ? grey[200] : amber[100]}`,
                //                backgroundColor: `${amber[100 + 100*depth]}`,
                // letterSpacing: 1,
                borderTopLeftRadius: 7,
                borderTopRightRadius: 7,
                borderBottomLeftRadius: expanded ? 0 : 7,
                borderBottomRightRadius: expanded ? 0 : 7,
                p: theme => theme.spacing(.5, 0, .5, 0),
                m: 0,
                // color: blueGrey[50],
                fontWeight: 500,
            }}
            onClick={() => onChange(!expanded)}
        >
            <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box>
                    <Box display="flex" alignItems="baseline">
                        <Typography
                            sx={{
                                fontSize: 20,
                                fontFamily: 'Montserrat, sans-serif',
                                // letterSpacing: 1,
                                p: theme => theme.spacing(1.25, 1, 0, 2),
                                m: 0,
                                // color: blueGrey[50],
                                fontWeight: 500,
                            }}
                        >
                            {subject.toString()}
                        </Typography>

                        <Typography
                            color="text.secondary"
                            sx={{
                                fontSize: 16,
                                // fontFamily: 'Montserrat, sans-serif',
                                m: 0,
                                fontWeight: 500,
                                flexGrow: 1,
                            }}
                        >
                            {index > 0 && `#${index}`}
                        </Typography>
                    </Box>

                    <Typography
                        sx={{
                            fontSize: 14,
                            fontFamily: 'Montserrat, sans-serif',
                            p: theme => theme.spacing(0, 1, 1, 2.1),
                        }}
                    >
                        {subject.description}
                    </Typography>
                </Box>
                {index > 0 && (
                    <Box pr={2}>
                        <ExpandMore
                            sx={{
                                transform: !expanded ? 'rotate(0deg)' : 'rotate(180deg)',
                                marginLeft: 'auto',
                                transition: theme => theme.transitions.create('transform', {
                                    duration: theme.transitions.duration.shortest,
                                }),
                            }}
                        />

                        {/* <ExpandMoreButton
                            expand={expanded}
                            onClick={() => onChange(!expanded)}
                            // aria-expanded={expanded}
                            aria-label="show more"
                        >
                            <ExpandMore />
                        </ExpandMoreButton> */}
                    </Box>
                )}
            </Box>
        </CardActionArea>
    );
}