import { Dashboard } from "@mui/icons-material";
import { Box, Divider, Typography } from "@mui/material";
import { grey, teal } from "@mui/material/colors";
import { useState } from "react";
import { EmailPasswordLoginForm } from "./EmailPassLoginForm";
import { FederatedLoginForm } from "./FederatedLoginForm";
import { PasswordRecoveryForm } from "./PasswordRecoveryForm";


export function LoginFunnel({ onCancel }) {

    const [view, setView] = useState("login");

    return (
        <Box sx={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
        }}>
            <Box sx={{
                textAlign: "center",
                p: theme => theme.spacing(8, 5, 5, 5),
                width: 400,
            }}>
                <Dashboard sx={{
                    fontSize: 70,
                    color: grey[800],
                }} />
                <Typography variant="h3"
                    sx={{
                        fontWeight: 900,
                        mb: 6,
                        letterSpacing: 2,
                        // color: teal[600],
                        color: grey[800],
                    }}
                >Designer</Typography>

                {view === "login" && (
                    <LoginView
                        onPasswordReset={() => setView("reset")}
                    />
                )}
                {view === "reset" && (<>
                    <Typography variant="h6" sx={{
                        mb: 4, fontWeight: 600,
                        color: grey[800],

                    }} >Password Recovery</Typography>
                    <PasswordRecoveryForm
                        onCancel={() => setView("login")}
                    />
                </>)}
            </Box>
        </Box>
    )
}

function LoginView({ onPasswordReset }) {

    const [disabled, setDisabled] = useState(false);

    return (
        <>
            <FederatedLoginForm
                onStateChange={state => setDisabled(state === "processing")}
                disabled={disabled}
            />
            <Divider sx={{
                fontSize: 12,
                color: grey[400],
                "&::before": {
                    // borderColor: amber[400],
                },
                "&::after": {
                    // borderColor: grey[400],
                },
                mt: 2,
                mb: 2,
            }}>
                or
            </Divider>
            <EmailPasswordLoginForm
                onPasswordReset={onPasswordReset}
                onStateChange={state => setDisabled(state === "processing")}
                disabled={disabled}
            />
            {/* {onCancel && (
                        <Button
                            onClick={onCancel}
                            size="large"
                            fullWidth
                            disabled={state === "processing"}
                            disableElevation
                            sx={{ color: grey[900], p: 2, }}
                        >
                            Cancel
                        </Button>
                    )} */}
        </>
    );
}