import { Box, CircularProgress, LinearProgress } from '@mui/material';
import { createContext, useContext, useEffect, useState } from 'react';
import { useUser } from '../../lib/user';
import { LoginFunnel } from 'component/auth/LoginFunnel';
import { useApp } from 'lib/app';
import { blueGrey } from '@mui/material/colors';

const ScreenContext = createContext([
  null,
  () => { },
]);

export function SplashScreen({ children }) {

  const [screen, setScreen] = useState(null);

  return (
    <ScreenContext.Provider value={[screen, setScreen]}>
      {screen === "login" && (<LoginScreen onCancel={() => setScreen(null)} onAuth={() => setScreen(null)} />)}
      <Box sx={{ display: screen === "login" ? "none" : "inherit" }}>
        {children}
      </Box>
    </ScreenContext.Provider>
  );
}

export function Protected({ children }) {

  const { user, state } = useUser();

  if (state === "processing") {
    return (
      <LinearProgress
        thickness={6}
        sx={{
          bgcolor: blueGrey[600],
          zIndex: 10000,
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          height: 6,
        }}
      />
    );
  }

  if (!user) {
    return (<LoginFunnel />);
  }

  return children;
}

function LoginScreen({ onCancel, onAuth }) {

  const { user } = useUser();

  useEffect(() => {
    if (user) {
      onAuth && onAuth();
    }

  }, [user]);

  return (<LoginFunnel onCancel={onCancel} />);
}

export const useSplashScreen = () => {
  return useContext(ScreenContext);
}
