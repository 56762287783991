import * as Auth from "firebase/auth";
import { useCallback, useState } from 'react';

const auth = Auth.getAuth();

export const useFederatedAuth = () => {

    const [error, setError] = useState(null);

    const signInWithGoogle = useCallback(() => {

        const googleAuthProvider = new Auth.GoogleAuthProvider();

        return Auth.signInWithPopup(auth, googleAuthProvider)
            .then((data) => {
                setError(null);
                return data;
            })
            .catch(error => {
                const { code, message } = error || {};
                setError({
                    code,
                    message,
                });
                throw error;
            });
    }, []);

    return {
        error,
        signInWithGoogle,
    }
}
