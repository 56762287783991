import { Box, Typography } from '@mui/material';

export function Subject({ subject }) {

  const label = !subject
    ? "[subject]"
    : subject.toString().toLowerCase();

  return (<Typography component="span" sx={{ fontSize: 20, fontWeight: 600, fontFamily: 'Montserrat, sans-serif', }}>{label}</Typography>);
}

export function Subjects({ ids }) {

  if (!ids || !Array.isArray(ids) ||  ids.length === 0) {
    return (<Typography sx={{ fontSize: 20, fontFamily: 'Montserrat, sans-serif', }} component="span">[subject]</Typography>);
  }

  return ids.map((s, i) => (
    <Box component={i > 0 ? "div" : "span"} key={i}>
      {i > 0 && (<Typography component="span" mr={1} sx={{ fontSize: 11,  }}>or</Typography>)}
      <Subject id={s} />
    </Box>
  ));
}
