import { Button, Stack } from "@mui/material";
import { blueGrey, green, grey, lightGreen, teal, yellow } from "@mui/material/colors";

export default function PrevNextButtons({ onPrev, onNext, prevLabel = "Prev", nextLabel = "Next", disabled = false, NextComponent = Button, ...props }) {
    return (
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
            {...props}
        >
            {onPrev && (
                <Button
                    onClick={onPrev}
                    fullWidth
                    disableElevation
                    size="large"
                    disabled={!onPrev || disabled}
                    sx={{
                        color: grey[100],
                    }}
                >
                    {prevLabel}
                </Button>
            )}
            {/* {onNext && ( */}
            <NextComponent
                type="submit"
                variant='contained'
                onClick={onNext}
                fullWidth
                disableElevation
                size="large"
                disabled={disabled}
                sx={{
                    "&.Mui-disabled": {
                        color: grey[600],
                        backgroundColor: blueGrey[900],
                    },
                    backgroundColor: teal[300],
                    // color: grey[800],
                }}
            >
                {nextLabel}
            </NextComponent>
            {/* )} */}
        </Stack>
    );
}