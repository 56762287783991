import { FormControl, FormControlLabel, Radio, RadioGroup, Stack, TextField, Typography } from '@mui/material';
import { FormPanel } from 'component/element/Layout';
import { useState } from 'react';
import { vocabularies } from 'resources/vocabularies';
import * as yup from 'yup';

const BackCamelSchema = yup.string()
  .trim()
  .required()
  .matches(/^[a-z][a-zA-Z]+$/, 'must start with a lower-case letter followed by letters [a-zA-Z] only')
  .min(4);

const CamelSchema = yup.string()
  .trim()
  .required()
  .matches(/^[A-Z][a-zA-Z]+$/, 'must start with a capital letter followed by letters [a-zA-Z] only')
  .min(4);

const VocabSchema = yup.string()
  .trim()
  .required()
  .url();

export const BackCamelIdSchema = yup.object({
  term: BackCamelSchema,
  vocab: VocabSchema,
});

export const CamelIdSchema = yup.object({
  term: CamelSchema,
  vocab: VocabSchema,
});

export function StepId({
  defaultValue: { term: defaultTerm, vocab: defaultVocab } = {},
  data: { name = "", verb = "" },
  error = false,
  syntax = "camel",
  onSubmit = () => { },
  onChange = () => { },
}) {

  return (
    <>
      <FormPanel>
        <TextField
          name="term"
          defaultValue={defaultTerm?.trim().length > 0 ? defaultTerm?.trim() : generateId(name, verb, syntax)}
          sx={{ bgcolor: "#fff" }}
          fullWidth
          autoFocus
          label="Id"
          variant='standard'
          error={!!error?.term}
          helperText={!!error?.term ? error?.term : ""}
          onBlur={e => onChange({ term: e.target.value.trim() })}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              onSubmit({ term: e.target.value.trim() });
            }
          }}
        />
      </FormPanel>

      <FormPanel mt={2}>
        <Typography variant="subtitle2" sx={{ fontWeight: 400, color: "text.secondary" }}>Vocabulary</Typography>
        <VocabularySelector
          defaultValue={defaultVocab}
          error={error?.vocab}
          onChange={vocab => onChange({ vocab })}
          onSubmit={vocab => onSubmit({ vocab })}
        />
      </FormPanel>
    </>
  );
}

function VocabularySelector({ defaultValue = "custom", error = false, onChange = () => { }, onSubmit = () => { } }) {

  const [value, setValue] = useState(Object.keys(vocabularies).includes(defaultValue) ? defaultValue : "");

  const isDefined = Object.keys(vocabularies).includes(value);

  const handleChange = event => {
    if (event.target.value === "custom") {
      setValue("");
      onChange(null);
      return;
    }
    setValue(event.target.value.trim());
    onChange(event.target.value.trim());
  };

  const handleVocabChange = (e) => {
    setValue(e.target.value.trim())
    onChange(e.target.value.trim());
  }

  const handleVocabSubmit = (e) => {
    setValue(e.target.value.trim())
    onSubmit(e.target.value.trim());
  }

  return (
    <Stack spacing={1} mb={1}>
      <FormControl>
        <RadioGroup
        name="vocab"
          row
          defaultValue={Object.keys(vocabularies).includes(defaultValue) ? defaultValue : "custom"}
          onChange={handleChange}
        >
          {Object.values(vocabularies).map(vocab => (
            <FormControlLabel value={vocab.id} control={<Radio />} label={vocab.name} key={vocab.id} />
          ))}
          <FormControlLabel value="custom" control={<Radio />} label="Custom" />
        </RadioGroup>
      </FormControl>

      <TextField
        name="vocab"
        label="Vocabulary URL"
        fullWidth
        value={value}
        disabled={isDefined}
        variant="standard"
        error={!!error}
        helperText={!!error ? error : ""}
        onChange={e => setValue(e.target.value.trim())}
        onBlur={handleVocabChange}
        onKeyPress={e => {
          if (e.key === 'Enter') {
            e.preventDefault();
            handleVocabSubmit(e);
          }
        }}
      />
    </Stack>
  );
}

function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

function camelize(str, back = false) {

  if (str?.trim().length <= 0) {
    return "";
  }

  return str.trim()
    .split(/(\s+)/)
    .filter(w => w.trim().length > 0)
    .map(w => w.trim())
    .filter(w => !["the", "a", "an", "been", "any", "some", "were", "was", "be", "as"].includes(w.toLowerCase()))
    .map((w, i) => (i > 0 || !back) ? capitalize(w) : w.toLowerCase())
    .join("");
}

function generateId(name, verb, syntax) {

  let id = name;

  if (verb?.length > 0 && !(["is", "has", "has been"].includes(verb))) {
    id = verb + " " + id;
  }

  return camelize(id, syntax === "backCamel")
}