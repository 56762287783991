
export const vocabularies = {

    "https://schema.org/": {
        name: "schema.org",
        id: "https://schema.org/",
    },
    "https://example.com/": {
        name: "example.com",
        id: "https://example.com/",
    }

}