import { Add } from '@mui/icons-material';
import { Button, FormControl, FormControlLabel, FormHelperText, Grid, Radio, RadioGroup, Skeleton, TextField, Typography } from '@mui/material';
import { createDraft } from 'component/element/Drafts';
import { ENTITY_DRAFT_KEY_PREFIX } from 'component/entity/EntityTypeDesigner';
import { EntityDocument } from 'document/entity';
import { getAllEntities } from 'document/entityService';
import { useApp } from 'lib/app';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import VocabSelector from './VocabSelector';

export function EntitySelector({
  defaultValue,
  error = false,
  onChange = () => { },
}) {

  const [subjects, setSubjects] = useState(null);

  const [subjectPath, setSubjectPath] = useState(defaultValue?.ref?.path || null);

  const { progress, notify } = useApp();

  const navigate = useNavigate();

  useEffect(() => {

    const dataFetch = async () => {
      //FIXME const snap = await getAllCredentialSubjects();
      const snap = await getAllEntities();
      setSubjects(snap.docs.map(doc => doc.data()));
    };

    progress(true);

    dataFetch()
      .catch((e) => notify(e, "error"))
      .then(() => progress(false))

    return () => progress(false);
  }, []);

  const handleChange = (event) => {

    const selected = subjects.find(s => s.ref.path === event.target.value);
    //    console.log("YYY", { ...selected });
    //    console.log("XXXX", Object.assign(new EntityDocument(), { ...selected }));

    if (selected) {
      onChange(Object.assign(new EntityDocument(), { ...selected }));
      setSubjectPath(event.target.value);
    }
  };

  const handleAddEntity = () => {
    const cloneId = Date.now();
    createDraft(ENTITY_DRAFT_KEY_PREFIX + cloneId, {}, 0, 5);
    navigate(`/entity/draft/` + cloneId);
  }

  return (
    <>
      <VocabSelector fullWidth />
      <TextField
        // disabled
        label="Name"
        size="small"
        value="[TODO]"
        fullWidth
        sx={{ mt: 1.5, }}
        variant="standard"
      />
      <FormControl
        sx={{ mt: 2, }}
        error={!!error}
        fullWidth
        variant="standard"
      >
        <Typography
          sx={{
            fontSize: 12,
            lineHeight: 1.5,
            // color: "rgba(0,0,0,.85)",
            mb: 1, mt: 1,
          }}
          color={!error ? "text.secondary" : "error"}
        >Subject</Typography>
        <RadioGroup
          value={subjectPath}
          onChange={handleChange}
          sx={{ ml: 1 }}
          name="ref"
        >
          <Grid
            container
            spacing={{ xs: 0, sm: 1, }}
            sx={{ maxHeight: 300, overflow: "auto" }}
            alignItems="center"
          >
            {subjects ? subjects.map((subject) => (
              <Grid item xs={12} sm={6} md={6} key={subject.ref.path}>
                <FormControlLabel
                  value={subject.ref.path}
                  label={subject.toString()}
                  control={<Radio />} />
              </Grid>
            )) : [...Array(5)].map((_, index) => (
              <Grid item xs={12} sm={6} md={6} key={index}>
                <Skeleton variant="rectangular" animation="wave" height={40} />
              </Grid>
            ))}
            {!!subjects && (
              <Grid item xs={12} sm={6} md={6} alignSelf="center">
                <Button disableElevation startIcon={<Add />} size="small" color="secondary"
                  onClick={handleAddEntity}
                >Add</Button>
              </Grid>
            )}
          </Grid>
        </RadioGroup>
        {!!error && (<FormHelperText sx={{ m: theme => theme.spacing(1, 0, 0, 0), p: 0 }}>{error}</FormHelperText>)}
      </FormControl>
    </>
  );
}