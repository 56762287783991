import { Add } from "@mui/icons-material";
import { Masonry } from "@mui/lab";
import { Box, Skeleton } from "@mui/material";
import { CardView } from "component/element/CardView";
import { DirectoryFilter } from "component/element/DirectoryFilter";
import { PageEnd, PageSubtitle, PageTitle } from "component/element/Layout";
import ButtonLink from "component/element/Link";
import { getAllEntities } from "document/entityService";
import { useApp } from "lib/app";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";


function EntityCard({ entity }) {

    const navigate = useNavigate();

    return (
        <CardView
            title={entity.toString()}
            description={entity.description}
            uri={entity.uri()}
            onClick={() => navigate(entity.ref.id)}
        />
    );
}


export default function EntityDirectory({ }) {

    const [entities, setEntities] = useState()
    const [sortBy, setSortBy] = useState("NAME");
    const { progress, notify } = useApp();

    useEffect(() => {
        const dataFetch = async () => {
            const snap = await getAllEntities();
            setEntities(snap.docs.map(doc => doc.data()));
        };

        progress(true);

        dataFetch()
            .catch((e) => notify(e, "error"))
            .then(() => progress(false))

    }, []);

    const list = entities || Array(3).fill(null);

    return (
        <Box>
            <Box direction="row" alignItems="baseline" mr={1} display="flex">
                <Box mr={2} sx={{ flexGrow: 0 }} maxWidth="sm">
                    <PageTitle>Entities</PageTitle>
                    <PageSubtitle>
                        An entity represents an identifiable structured data type.
                    </PageSubtitle>
                </Box>
                <Box sx={{ flexGrow: 1 }} />
                <ButtonLink href="/entity/designer" startIcon={<Add />}>new entity</ButtonLink>
            </Box>
            <Box mt={4} mr={1}>
                <DirectoryFilter sortBy={sortBy} onSortChange={setSortBy} />
            </Box>
            <Box mt={2}>
                {list?.length > 0 && (
                    <Masonry columns={{ xs: 1, sm: 2, md: 3, lg: 3 }} spacing={1}>
                        {list
                            .sort((a, b) => {
                                if (sortBy === "TERM") {
                                    return a.term.localeCompare(b.term);
                                }
                                return (a?.name || "").localeCompare((b?.name || ""));
                            })
                            .map((entity, index) => !!entity
                                ? (<EntityCard entity={entity} key={entity.ref.path} />)
                                : (<Skeleton animation="wave" height={150} key={index} sx={{ transform: "none" }} />)
                            )}
                    </Masonry>
                )}
                {list?.length === 0 && (
                    <Box>No entities have been found</Box>
                )}
            </Box>
            <PageEnd />
        </Box>
    );
}