import { Add, ArrowBack, Close, CopyAll, Delete, DeleteForever, Edit } from "@mui/icons-material";
import { Box, Button, Card, CardContent, Divider, Grid, Skeleton, Toolbar, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { CLAIM_DRAFT_KEY_PREFIX } from "component/claim/ClaimTypeDesigner";
import { CardView, CardViewStub } from "component/element/CardView";
import { createDraft } from "component/element/Drafts";
import { PageEnd, PageSubtitle, PageTitle } from "component/element/Layout";
import ButtonLink from "component/element/Link";
import ProtectedButton from "component/element/ProtectedButton";
import { VocabTermTableView } from "component/element/TableView";
import { deleteClaim } from "document/claimService";
import { getEntity } from "document/entityService";
import { useApp } from "lib/app";
import moment from "moment";
import { useEffect, useState } from "react";
import { useLoaderData, useNavigate } from "react-router-dom";

function Line({ mt, label, value }) {
    return (
        <Box mt={mt}>
            <Typography variant="body2" component="span" sx={{ pr: 1, fontWeight: 700, }}>
                {label}:
            </Typography>
            <Typography component="span" sx={{ fontSize: 16 }}>
                {value}
            </Typography>
        </Box>
    )
}

export function ClaimOverview({ entity, ...params }) {
    return (<Box {...params}>
        <Typography variant="h5" gutterBottom>{entity?.toString()}</Typography>
        <Typography variant="body1">
            {entity.description}
        </Typography>
        <Box mt={2}>
            <Line label="Term" value={entity.term} />
            <Line label="Vocabulary" value={entity.vocab} />
            {entity.verb && entity.verb.length > 0 && (
                <Line label="Verbs" value={entity.verb.join(", ")} mt={2} />
            )}
        </Box>
        {!!entity.scope?.credentialSubject && (
            <Typography mt={2} sx={{ fontSize: 15, }}>
                A verifiable credential can be issued to this entity as a subject.
            </Typography>
        )}
    </Box>);
}

function ClaimDeleteConfirmation({ claim, onCancel }) {

    const navigate = useNavigate();
    const { progress, notify } = useApp()

    const handleDelete = () => {
        progress(true);
        deleteClaim(claim.ref.id)
            .then(() => {
                notify("A claim has been deleted.", "info");
                navigate(-1);
            })
            .catch((e) => notify(e, "error"))
            .then(() => progress(false))
    }

    return (
        <Toolbar sx={{ m: 0, p: 0, width: "100%" }} disableGutters>
            <ProtectedButton
                startIcon={<DeleteForever />}
                onClick={handleDelete}
                disabled={!claim}
                color="error"
                variant="contained"
                elevation={0}
            >
                Delete Forever
            </ProtectedButton>
            <Box sx={{ flexGrow: 1, }}></Box>
            <Button
                startIcon={<Close />}
                onClick={onCancel}
            >
                Cancel
            </Button>
        </Toolbar>
    );
}

function ClaimActions({ claim, onDelete }) {

    const navigate = useNavigate();

    const handleClone = () => {
        const cloneId = Date.now();
        const { ref, name = "", term = "", ...data } = claim;
        createDraft(CLAIM_DRAFT_KEY_PREFIX + cloneId, {
             ...data, 
             name: (name + " clone").trim(), 
             term: (term + "Clone").trim(),
             modified: null,
            }, 0, 5);
        navigate(`/claim/draft/` + cloneId);
    };

    return (
        <Toolbar sx={{ p: 0, width: "100%" }} disableGutters>
            <ButtonLink color="secondary" startIcon={<ArrowBack />} to="/claim/">Directory</ButtonLink>
            <Button
                startIcon={<CopyAll />}
                onClick={handleClone}
                disabled={!claim}
            >
                Clone &amp; Design
            </Button>
            <Divider />
            <ProtectedButton
                startIcon={<Edit />}
                disabled={!claim || true}
            >
                Edit [TODO]
            </ProtectedButton>
            <Box sx={{ flexGrow: 1, }}></Box>
            <ProtectedButton
                startIcon={<Delete />}
                onClick={onDelete}
                disabled={!claim}
                color="error"
            >
                Delete
            </ProtectedButton>
        </Toolbar>
    );
}

function SectionLabel({ children }) {
    return (
        <Typography
            gutterBottom
            sx={{
                fontSize: 15,
                fontWeight: 500,
                textTransform: "uppercase",
                fontFamily: 'Montserrat, sans-serif',
                color: "text.secondary",
                mb: 1,
            }}>
            {children}
        </Typography>
    );
}

export function ClaimView({ }) {

    const [mode, setMode] = useState(null);

    const claim = useLoaderData();

    const navigate = useNavigate();

    const handleAddSubject = () => {
        const cloneId = Date.now();
        createDraft(CLAIM_DRAFT_KEY_PREFIX + cloneId, { ...claim, subject: null, modified: null }, 0, 5);
        navigate(`/claim/draft/` + cloneId);
    }

    return (
        <Box>
            <Box>
                <PageTitle>
                    Claim: {claim.toString()}
                </PageTitle>
                <PageSubtitle>
                    {claim.description}
                </PageSubtitle>
            </Box>
            <VocabTermTableView mt={3} term={claim.term} vocab={claim.vocab} />
            <Box color={grey[50]} borderRadius={3} mr={2} mt={2} mb={2}>
                {!mode && (<ClaimActions claim={claim} onDelete={() => setMode("delete")} />)}
                {mode === "delete" && (<ClaimDeleteConfirmation claim={claim} onCancel={() => setMode(null)} />)}
            </Box>
            <Box mt={4}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={6}>
                        <SectionLabel>Subject</SectionLabel>
                        <Box sx={{ mr: 2, ml: 1, mb: 1 }}>
                            <ClaimEntityView entity={claim.subject} navigate={navigate} />
                        </Box>
                        <Button startIcon={<Add />} onClick={handleAddSubject}>New Subject</Button>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} mt={{ xs: 2, sm: 2, md: 0 }}>
                        <SectionLabel>{claim.object.type === "entity" ? "Object" : "Attribute"}</SectionLabel>
                        <Box sx={{ mr: 2, ml: 1, mb: 1 }}>
                            {claim.object.type === "entity" && (
                                <ClaimEntityView entity={claim.object.entity} navigate={navigate} />
                            )}
                            {claim.object.type === "attribute" && (
                                <Card
                                    elevation={0}
                                    sx={{
                                        // bgcolor: lightBlue[50],
                                        // borderColor: alpha(lightBlue[50], .5),
                                        // borderWidth: 1,
                                        // borderStyle: "solid",
                                    }}
                                >
                                    <CardContent>
                                        <Typography
                                            sx={{
                                                fontSize: 20,
                                                fontWeight: 600,
                                                fontFamily: 'Montserrat, sans-serif',
                                            }}
                                            component="div"
                                        >
                                            {claim.name}
                                        </Typography>
                                        <Typography color={grey[900]} sx={{ textTransform: "uppercase", fontSize: 13 }}>
                                            {claim.object.toString()}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            )}
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Box mt={3}>
                <SectionLabel>Usage</SectionLabel>
                <Box sx={{ mr: 2, ml: 1, mb: 1 }}>
                    <Typography>a list of credential types [TODO]</Typography>
                    <Button startIcon={<Add />} disabled>New Credential Type [TODO]</Button>
                </Box>
            </Box>
            {claim.modified && (
                <Box mt={3}>
                    <Typography color="text.secondary" sx={{ fontSize: 12, }}>
                        Last modified: {moment(claim.modified).format('lll')}
                    </Typography>
                </Box>
            )}
            <PageEnd />
        </Box>
    );
}

function ClaimEntityView({ entity, navigate }) {

    if (!entity.ref?.path) {
        return (
            <CardViewStub
                title={entity.toString()}
                description={entity.description}
                uri={entity.uri()}
            />
        );
    }

    return (<ClaimEnitityCheck entity={entity} navigate={navigate} />);
}

function ClaimEnitityCheck({ entity, navigate }) {

    const [record, setRecord] = useState(null);

    const { progress } = useApp();

    useEffect(() => {
        const get = async (path) => {
            try {
                progress(true);
                const doc = await getEntity(path);
                setRecord(doc);
                progress(false);

            } catch (e) {
                console.log(e);
            }
        }
        if (record === null) {
            get(entity.ref.path);
        }
    }, []);
    
    if (record === null) {
        return (
            <CardView
            title={entity.toString()}
            description={entity.description}
            uri={entity.uri()}
            onClick={() => navigate(`/entity/${entity.ref.id}`)}
        />
        // <Skeleton animation="wave" height={100} variant="rectangular" />
        );
    }

    if (!record?.exists()) {
        return (
            <CardViewStub
                title={entity.toString()}
                description={entity.description}
                uri={entity.uri()}
            />
        );
    }

    const document = record.data();

    const newer = document?.modified && (!entity.modified
        || document.modified.getTime() > entity.modified.getTime());

    return (
        <CardView
            title={entity.toString()}
            description={entity.description}
            uri={entity.uri()}
            onClick={() => navigate(`/entity/${entity.ref.id}`)}
            updateAvaiable={newer}
        />
    );
}