import { Link, Public, Security } from "@mui/icons-material";
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Stack, Typography } from "@mui/material";


const TYPES = [
  {
    "name": "Public ",
    "code": "PUBLIC",
    "subtitle": "Anyone"
  },
  {
    "name": "Link",
    "code": "LINK",
    "subtitle": "Anyone with a link"
  },
  {
    "name": "Private",
    "code": "PRIVATE",
    "subtitle": "Just me"
  }
]

const accessIcon = {
  "LINK": Link,
  "PUBLIC": Public,
  "PRIVATE": Security,
}

export function AccessSelector({ }) {
  return (
    <FormControl disabled>
    <FormLabel id="demo-controlled-radio-buttons-group">Privacy</FormLabel>
    <RadioGroup
      name="access"
      value="PUBLIC"
      
    >
      {TYPES.map((accessType) => {

        const RadioIcon = accessIcon[accessType.code];

        return (
          <FormControlLabel key={accessType.code} value={accessType.code}
            control={<Radio />}
            label={(<Stack direction="row" spacing={2} alignItems="center" m={1}>
              <RadioIcon sxx={{ fontSize: 40, color: "#4a4a4a", }} />
              <Stack>
                <Typography>{accessType.name}</Typography>
                <Typography variant="subtitle2"> {accessType.subtitle}</Typography>
              </Stack>
            </Stack>)}
          />
        )
      })}
    </RadioGroup>
</FormControl>
  );
};
