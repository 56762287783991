import { GitHub, Google } from "@mui/icons-material";
import { Button, FormHelperText, Stack, Typography } from "@mui/material";
import { useEffect } from "react";
import { useApp } from "../../lib/app";
import { useFederatedAuth } from "../../lib/auth";

export function FederatedLoginForm({ onStateChange, disabled }) {

    const { signInWithGoogle, error } = useFederatedAuth();

    const { progress } = useApp();

    const handleSignInWithGoogle = () => {
        progress(true);
        onStateChange("processing");
        signInWithGoogle()
            .then(() => { onStateChange("idle"); progress(false); })
            .catch(() => { onStateChange("error"); progress(false); });
    }

    return (
        <Stack spacing={1}>
            <Button
                onClick={handleSignInWithGoogle}
                variant="contained"
                size="large"
                startIcon={<Google />}
                fullWidth
                disabled={disabled}
                disableElevation
                sx={{
                    p: 1.5,
                    borderRadius: 3,
                }}
            >
                Sign in with Google
            </Button>
            {/* <Button
                onClick={signInWithGoogle}
                variant="contained"
                size="large"
                startIcon={<GitHub />}
                fullWidth
                disabled={true || disabled}
                disableElevation
                sx={{
                    p: 1.5,
                    borderRadius: 3,
                }}
            >
                Sign in with GitHub
            </Button> */}
            {!!error && (
                <Typography 
                    color="error" 
                    variant="body2" 
                    p={1}
                    sx={{ fontSize: 13, }}
                    >Access Denied</Typography>
            )}
        </Stack>
    )
}