import { Box, Typography } from '@mui/material';
import { StatementObject } from 'component/element/StatementObject';
import { Subject } from 'component/element/Subject';

export function ClaimStatement({ claim }) {
  return (<>
    <Subject subject={claim?.subject} />
    <Typography component="span" ml={1} sx={{
      fontWeight: 400, fontSize: 20,
      fontFamily: 'Montserrat, sans-serif',
    }}>
      {claim?.verb?.toLowerCase() || "[relation]"} {claim?.name ? claim.name + " " : ""}
    </Typography>
  </>
  )
}

export default function ClaimTypePreview({ value }) {
  return (
    <Box ml={1}>
      <ClaimStatement claim={value} />
      <StatementObject value={value?.object} />
    </Box>
  );
}
