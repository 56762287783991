import { Box } from "@mui/material";
import { vocabularies } from "resources/vocabularies";


export function VocabTermTableView({ term, vocab, ...props }) {

    const vocabName = vocabularies[vocab]?.name || vocab;

    return (
        <Box sx={{
            fontFamily: 'Montserrat, sans-serif',
        }} {...props}>
            <Box>
                <Box component="span" sx={{
                    fontSize: 14,
                    display: "inline",
                    mr: 1,
                    fontWeight: 500,
                }}>
                    Term:
                </Box>
                <Box compoent="span" sx={{
                    fontFamily: 'Montserrat, sans-serif',
                    display: "inline",
                }}>
                    {term}
                </Box>
            </Box>
            <Box>
                <Box component="span" sx={{
                    fontSize: 14,
                    display: "inline",
                    mr: 1,
                    fontWeight: 500,
                }}>
                    Vocabulary:
                </Box>
                <Box compoent="span" sx={{
                    fontFamily: 'Montserrat, sans-serif',
                    display: "inline",
                }}>
                    {vocabName}
                </Box>
            </Box>
        </Box>
    );
}