import { TextField } from '@mui/material';
import { FormPanel } from 'component/element/Layout';
import * as yup from 'yup';

StepDescription.Schema = yup.string().trim().required().min(10);

export function StepDescription({
  defaultValue,
  error = false,
  onChange = () => { },
}) {

  const handleChange = event => {
    onChange(event.target.value.trim());
  }

  return (
    <FormPanel>
      <TextField
        name="description"
        multiline
        rows={8}
        defaultValue={defaultValue || ""}
        fullWidth
        autoFocus
        variant="standard"
        error={!!error}
        helperText={!!error ? error : ""}
        onBlur={handleChange}
      />
    </FormPanel>
  );
}