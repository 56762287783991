import { Add } from "@mui/icons-material";
import { Masonry } from "@mui/lab";
import { Box, Skeleton, Stack, TextField, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { CardView } from "component/element/CardView";
import { DirectoryFilter } from "component/element/DirectoryFilter";
import { PageEnd, PageTitle, PageSubtitle } from "component/element/Layout";
import ButtonLink from "component/element/Link";
import { getAllClaims } from "document/claimService";
import { useApp } from "lib/app";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";


function ClaimCard({ entity }) {

    const navigate = useNavigate();

    return (
        <CardView
            title={entity.toString()}
            subtitle={entity.object.isAttribute() && entity.object.toString()}
            description={entity.description}
            uri={entity.uri()}
            onClick={() => navigate(entity.ref.id)}
        />
    );
}


export default function ClaimDirectory({ }) {

    const [claims, setClaims] = useState()
    const [sortBy, setSortBy] = useState("NAME");
    const { progress, notify } = useApp();

    useEffect(() => {
        const dataFetch = async () => {
            const docs = await getAllClaims();
            setClaims(docs);
        };

        progress(true);

        dataFetch()
            .catch((e) => notify(e, "error"))
            .then(() => progress(false))

    }, []);

    const list = claims || Array(3).fill(null);

    return (
        <Box>
            <Box direction="row" alignItems="baseline" mr={1} display="flex">
                <Box mr={2} sx={{ flexGrow: 0 }} maxWidth="md">
                    <PageTitle>Claims</PageTitle>
                    <PageSubtitle>
                        A claim is subject-verb-object statement crediting the subject with an attribute or with a relation to an entity.
                    </PageSubtitle>
                </Box>
                <Box sx={{ flexGrow: 1 }} />
                <ButtonLink href="/claim/designer" startIcon={<Add />} sx={{ flexGrow: 0, whiteSpace: "nowrap" }}>new claim</ButtonLink>
            </Box>
            <Box mt={4} mr={1}>
                <DirectoryFilter sortBy={sortBy} onSortChange={setSortBy} />
            </Box>
            <Box mt={3}>
                {list?.length > 0 && (
                    <Masonry columns={{ xs: 1, sm: 2, md: 3, lg: 3, xg: 3 }} spacing={1}>
                        {list
                            .sort((a, b) => {
                                if (sortBy === "TERM") {
                                    return a.term.localeCompare(b.term);
                                }
                                return (a?.name || "").localeCompare(b?.name || "");
                            })
                            .map((claim, index) => !!claim
                                ? (<ClaimCard entity={claim} key={claim.ref.path} />)
                                : (<Skeleton animation="wave" height={150} key={index} sx={{ transform: "none" }} />)
                            )}
                    </Masonry>
                )}
                {list?.length === 0 && (
                    <Box>No claims have been found</Box>
                )}
            </Box>
            <PageEnd />
        </Box>
    );
}