import { Alert, LinearProgress, Slide, Snackbar } from "@mui/material";
import { blueGrey } from "@mui/material/colors";
import { Box } from "@mui/system";
import {
    createContext, useCallback, useContext,
    useState
} from "react";

const VALUE = {
    notify: (message, type) => { },
    progress: (enabled) => { },
    fatalError: (e) => { console.error(e) },
};

const AppContext = createContext(VALUE);

export function AppContextProvider({ children }) {

    const [progress, setProgress] = useState(false);
    const [{ message, type, timestamp }, setNotification] = useState({ type: "success" });

    const handleClose = () => {
        setNotification(({ type }) => ({ type }));
    }

    const notifyCbx = useCallback((data, type = "success") => {

        const message = (typeof data === 'string' || data instanceof String)
            ? data
            : JSON.stringify(data)

        if (type === "error") {
            console.error(data);
        }

        setNotification({ message, type, timestamp: Date.now() });
        return () => { };    // nothing to sweep
    }, []);

    const progressCbx = useCallback((enabled = true) => {
        setProgress(enabled);
        return () => setProgress(false);   // disable proogress when leaving page
    }, []);

    VALUE.notify = notifyCbx;
    VALUE.progress = progressCbx;

    return (
        <AppContext.Provider value={VALUE}>
            <Box>
                {children}
            </Box>

            {progress && (
                <LinearProgress
                    thickness={4}
                    sx={{
                        bgcolor: blueGrey[600],
                        zIndex: 10000,
                        position: "fixed",
                        top: 0,
                        left: 0,
                        right: 0,
                    }}
                />
            )}

            <Snackbar open={!!message} autoHideDuration={6000} onClose={handleClose}
                TransitionComponent={(props) => (<Slide {...props} direction="left" timeout={750} mountOnEnter unmountOnExit />)}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                sx={{ minWidth: "35ch" }}
            >
                <Alert
                    onClose={handleClose}
                    severity={type || "success"}
                    // variant="outlined"
                    sx={{
                        width: "100%",
                        padding: 2,
                        fontSize: "1rem",
                        // bgcolor: "white",
                    }}>
                    {message || ""}
                </Alert>
            </Snackbar>
        </AppContext.Provider>
    );
}

export function useApp() {
    return useContext(AppContext);
}
