import * as Auth from "firebase/auth";
import "./firebase";
import {
    createContext, useCallback, useContext, useEffect, useReducer
} from "react";
import { UserDocument } from "../document/user";

const UserContext = createContext({
    signout: () => { },
});

export function UserProvider({ children }) {
    const user = useUserProvider();
    return <UserContext.Provider value={user}>{children}</UserContext.Provider>
}

export const useUser = () => {
    return useContext(UserContext);
}

function useUserProvider() {

    const [state, dispatch] = useReducer((_state, _value) => {

        if (!_value) {
            return { state: "done" };
        }

        if (_value.state !== null && _value.state !== undefined) {
            return {
                ..._state,
                state: _value.state,
            }
        }

        if (_value.user) {
            return {
                user: _value.user,
                state: "done",
            }
        }

        if (_value.error) {
            return {
                ..._state,
                error: _value.error,
                state: "error",
            }
        }

        return _state;

    }, { state: "processing", user: null, error: null });

    const auth = Auth.getAuth();

    const signout = useCallback(() =>
        cancelable(dispatch,
            () => Auth.signOut(auth)
        ), [auth]);

    const reload = useCallback(() =>
        cancelable(dispatch,
            () => Auth.reload(auth.currentUser),
            () => authenticate(auth.currentUser),
        ),
        [auth]);

    useEffect(() => {
        return Auth.onAuthStateChanged(auth, async (newAuth) => {

            if (newAuth) {
                try {
                    const user = await authenticate(newAuth);
                    dispatch(user);

                } catch ({ code, message }) {
                    dispatch({
                        error: {
                            code,
                            message,
                        }
                    });
                }

            } else {
                dispatch();
            }
        });

    }, [auth]);

    return { ...state, reload, signout };
}

function cancelable(dispatch, fnc, then) {
    let active = true;

    dispatch({ state: "processing" });

    fnc()
        .then(data => {
            if (active && then) {
                return then(data).then((_data = { state: "done" }) => {
                    active && dispatch(_data);
                });
            }
            active && dispatch({ state: "done" });
        })
        .catch((error) => {

            const { code, message } = error;
            active && dispatch({
                error: {
                    code,
                    message,
                }
            });
        })

    return () => active = false;
}

const authenticate = async (newAuth) => {
    // update state
    if (newAuth) {
        return {
            user: Object.assign(new UserDocument(), {
                uid: newAuth.uid,
                displayName: newAuth.displayName,
                email: newAuth.email,
                emailVerified: newAuth.emailVerified,
                avatar: newAuth.photoURL,
            })
        };
    }
    return null;
}
