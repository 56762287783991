import { Box, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { StatementObject } from 'component/element/StatementObject';
import { Subject } from 'component/element/Subject';

export default function CredentialTypePreview({ value }) {

  return (
    <Box>
      <Box sx={{ textAlign: "center", }}>
        {/* <Typography sx={{ fontSize: 13, fontWeight: 300, textAlign: "right" }}>
          {value?.id || "[ID]"}
        </Typography> */}

        <Typography mt={2} sx={{ fontWeight: 500, fontSize: 22, fontFamily: 'Montserrat, sans-serif', }}>
          {value?.name || "[NAME]"}
        </Typography>

        <Typography variant="body2" m={1} mb={1} sx={{
          fontStyle: "italic", fontSize: 14, color: "text.secondary",

        }}>
          This is to certify that
        </Typography>

        <Box m={1.5}>
          <Subject subject={value?.subject} />
        </Box>

        {/* <Typography variant="body2" mt={1} sx={{ fontStyle: "italic" }}>
          has/is
        </Typography> */}

        <Box mt={1.5}>
        <Box  justifyContent="center" display="flex">
                <Box textAlign="left" width="auto">

          {(!value?.claims || value.claims.length === 0)
            ? (<Typography>[CLAIMS]</Typography>)
            : Array.isArray(value.claims) && value.claims.map((v, i) => (
              <Box key={i}>              
                  <Typography component="span" sx={{ fontSize: 16, fontFamily: 'Montserrat, sans-serif', }}>
                    {v.mandatory && ("*")} {v.verb} {v.name}  {" "}

                  </Typography>
                  <StatementObject value={v?.object} />
                </Box>
              
            ))
          }
                      </Box>
            </Box>
        </Box>

        {/* <Box mt={4} mb={2}>
          {!value?.description ? (
            <Typography variant="body2" sx={{ fontSize: 12 }}>
              [DESCRIPTION]
            </Typography>
          ) : (
            <>
              {value?.description.split("\n").map((line, number) => (<Typography key={number} pl={3} pr={3} variant="body1" sx={{ fontSize: 13 }}>{line}</Typography>)) || "[DESCRIPTION]"}
            </>
          )}
        </Box> */}

      </Box>
      <Typography sx={{
        fontSize: 10, textAlign: "left",
        fontFamily: 'Montserrat, sans-serif',
        borderTopStyle: "dashed",
        borderTopColor: grey[400],
        color: grey[800],
        borderTopWidth: 1,
        pt: 0.5,
      }} m={theme => theme.spacing(6, 2, 0, 2)}>
        This is a preview. Please note that an issued credential provides more information, like an issuer, issuance and expiration date, an actual holder, etc.
      </Typography>
    </Box>
  );
}
