import { Close, Delete } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import { getStorageItem, removeStorageItem, setStorageItem, useLocalStorage } from "lib/storage";
import { useState } from "react";
import ButtonLink from "./Link";

function draftFromJson(key, filter, draft) {
    const timestamp = key.substring(filter.prefix.length);
    return {
        id: key,
        url: filter.toUrl(timestamp),
        created: new Date(Number(timestamp)),
        modified: new Date(draft.modified),
        label: filter.label(draft.data),
    };
}

function fetchDrafts(drafFilters) {

    const filters = Array.isArray(drafFilters) ? drafFilters : [drafFilters];

    const filter = (key) => filters.find(_filter => key.startsWith(_filter.prefix));
    //    const converter = (key) => filters.find(filter => key.startsWith(filter.prefix))?.converter;

    return Object.entries(window.localStorage)
        .filter(([key, _]) => !!filter(key))
        .map(([key, value]) => [key, JSON.parse(value)])
        .map(([key, value]) => {

            const def = filter(key);
            const draft = draftFromJson(key, def, value);

            return draft;
        });
}

export function useDraft(draftId, def, defaultData) {

    const [draft, setDraft] = useLocalStorage(
        draftId,
        { step: 0, completed: 0, data: defaultData },
        def?.converter || null,
    );

    return [
        draft,
        setDraft,
    ];
}

export function getDraft(def, id) {
    return getStorageItem(window.localStorage, def.prefix + id, def.converter);
}

export function createDraft(draftId, data, step = 0, completed = 0) {
    setStorageItem(window.localStorage, draftId, { step, completed, data });
}

export function deleteDraft(draftId) {
    removeStorageItem(window.localStorage, draftId);
}

export function useDrafts(drafFilters) {

    const [drafts, setDrafts] = useState(fetchDrafts(drafFilters));

    const deleteDraft = (key) => {
        window.localStorage.removeItem(key);
        setDrafts(fetchDrafts(drafFilters));
    };

    return { drafts, deleteDraft };
}

export function DraftCardActions({ id, url, onDelete = null, }) {

    const [mode, setMode] = useState(null);

    const handleDelete = () => {
        onDelete && onDelete(id);
    }

    return (
        <Box sx={{ display: "flex", width: "100%" }}>
            {!mode && (<>
                {!!url && (<ButtonLink to={url} size="small">Continue</ButtonLink>)}
                <Box sx={{ flexGrow: 1 }} />
                <Button size="small" onClick={() => setMode("delete")} startIcon={<Delete />}
                    color="error"
                    disabled={!onDelete}>Discard</Button>
            </>)}
            {mode === "delete" && (<>
                <Button
                    onClick={handleDelete}
                    color="error"
                    startIcon={<Delete />}
                    size="small"
                    variant="contained"
                    disableElevation
                >Discard</Button>

                <Box sx={{ flexGrow: 1 }} />

                <Button
                    onClick={() => setMode()}
                    startIcon={<Close />}
                    size="small"
                >Cancel</Button>
            </>)}
        </Box>
    );
}
