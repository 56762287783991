
export function removeEmptyValues(obj) {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v && v != null));
}

export function deepRemoveEmptyValues(obj) {
    return Object.fromEntries(
      Object.entries(obj)
        .filter(([_, v]) => v && v != null)
        .map(([k, v]) => [k, v === Object(v) ? removeEmptyValues(v) : v])
    );
  }
