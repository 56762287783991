import { Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, Grid } from '@mui/material';
import { FormPanel } from 'component/element/Layout';
import { useState } from 'react';


StepVerb.Controlled = true;

const Verbs = [
  "is",
  "has",
  "owns",
  "seeks",
  "follows",
  "knows",
  "makes",
  "works"
];

export function StepVerb({
  defaultValue,
  error = false,
  onChange = () => { },
}) {

  const [verbs, setVerbs] = useState(defaultValue || []);

  const handleChange = event => {

    let modified = verbs;

    if (event.target.checked) {
      // add
      modified = [...verbs, event.target.value];

    } else {
      // remove
      modified = verbs.filter(verb => verb !== event.target.value);
    }
    setVerbs(modified);
    onChange(modified);
  }

  return (
    <FormPanel>
      <FormControl error={!!error}>
        <FormGroup>
          <Grid container spacing={{ xs: 1, sm: 2, }}>
            {Verbs.map((verb, index) => (
              <Grid item xs={12} sm={6} md={4} key={verb}>
                <FormControlLabel
                  control={<Checkbox
                    defaultChecked={index < 2 || defaultValue?.includes(verb)}
                    disabled={index < 2}
                    value={verb}
                    onChange={handleChange}
                  />}
                  label={verb}
                />
              </Grid>
            ))}
          </Grid>
        </FormGroup>
        {!!error && (<FormHelperText>{error}</FormHelperText>)}
      </FormControl>
    </FormPanel>
  );
}