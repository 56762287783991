import { Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { Box } from "@mui/system";
import { FormPanel } from "component/element/Layout";
import { NextAction } from "component/element/NextAction";
import ProtectedButton from "component/element/ProtectedButton";
import { MultiFormStep } from "../../element/MultiForm";
import PrevNextButtons from "../../element/PrevNextButtons";


function Line({ mt, label, value, sx }) {
    return (
        <Box mt={mt}>
            <Typography variant="body2" component="span" color="text.secondary" sx={{ pr: 1, fontWeight: 700, }}>
                {label}:
            </Typography>
            <Typography component="span" sx={{ fontSize: 16, ...sx }}>
                {value}
            </Typography>
        </Box>
    )
}

function CredentialTypeOverview({ value, ...params }) {
    return (<Box {...params}>
        <Typography
            variant="h5"
            gutterBottom
            sx={{
                fontFamily: 'Montserrat, sans-serif',
            }}
        >
            {value?.toString()}
        </Typography>

        <Typography variant="body1" sx={{ fontFamily: 'Montserrat, sans-serif', }}>
            {value.description}
        </Typography>

        <Box mt={2}>
            <Line label="Term" value={value.uri()} sx={{
                fontSize: 14, color: grey[800],
            }} />
        </Box>
        <Box mt={2}>
            <Typography
                sx={{
                    fontSize: 16,
                    mb: .5,
                    fontWeight: 400,
                    textTransform: "uppercase",
                    fontFamily: 'Montserrat, sans-serif',
                }}
            >
                Subject
            </Typography>

            <Box ml={1}>
                <Typography sx={{ fontFamily: 'Montserrat, sans-serif', fontWeight: 600, }}>
                    {value.subject.toString()}
                </Typography>
                <Box sx={{
                    fontSize: 14, color: grey[800],
                }}>
                    {value.subject.uri()}
                </Box>
                <Box sx={{

                }}>
                    {value.description}
                </Box>
            </Box>
        </Box>
        <Box mt={2}>
            <Typography sx={{ fontSize: 16, mb: .5, fontWeight: 400, textTransform: "uppercase", fontFamily: 'Montserrat, sans-serif', }}>Claims</Typography>
            <Box ml={1}>
                {value.claims && value.claims.map((claim, ci) => (
                    <Box key={claim.ref.path} mt={ci > 0 ? 1 : 0}>
                        <ClaimView claim={claim} index={[ci + 1]} />
                    </Box>
                ))}

            </Box>
        </Box>

    </Box>);
}

function ClaimView({ claim, index = [], depth = 0 }) {
    return (<Box>
        <Box display="flex" alignItems="stretch">
            <Box flexGrow={0} display="flex" alignItems="center">
                <Box sx={{
                    fontSize: 12,
                    p: t => t.spacing(0, 1.5, 0, 0),
                }}>
                    {(index.join(".") + ".")}
                </Box>
            </Box>
            <Box flexGrow={1}>
                <Typography sx={{ fontFamily: 'Montserrat, sans-serif', fontWeight: 600, }}>
                    the {depth === 0 ? "subject" : claim.subject.name} {claim.toString()} {claim.object.isRel() && claim.object.toString()}
                </Typography>
                <Box sx={{
                    textTransform: "uppercase",
                    fontSize: 12,

                }}>
                    {claim.object.isAttribute()
                        ? (claim.mandatory ? "mandatory " : "") + claim.object.toString().replace("value", "attribute")
                        : "relation"
                    }
                </Box>
                <Box
                    sx={{
                        fontSize: 14, color: grey[700],
                    }}
                >
                    {claim.uri()}
                </Box>
            </Box>
        </Box>
        {claim.claims && claim.claims.map((subclaim, si) => (
            <Box ml={2} mt={1} key={subclaim.ref.path} >
                <ClaimView claim={subclaim} index={[...index, si + 1]} depth={depth + 1} />
            </Box>
        ))}
    </Box>);
}

export default function StepCredentialTypeReview({ value, onPrev, onNext, mode = "edit" }) {

    return (
        <MultiFormStep label="Review">
            <FormPanel>
                <CredentialTypeOverview value={value} m={t => t.spacing(0, 1, 2, 1)} />
            </FormPanel>

            <PrevNextButtons
                mt={2}
                mb={1}
                onPrev={onPrev}
                nextLabel="Create"
                NextComponent={ProtectedButton}
                onNext={onNext}
                disabled={mode !== "edit"}
            />

            <FormPanel mt={2}>
                <NextAction />
            </FormPanel>
        </MultiFormStep>
    );
}