import { Delete } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import { useApp } from 'lib/app';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { deleteDraft, useDraft } from './Drafts';
import { PageEnd, PageSubtitle, PageTitle } from './Layout';
import { MultiForm } from "./MultiForm";

function DesignerSteps({
  defaultData,
  steps,

  draftId,
  draftDef,

  simple = false,

  DocumentType,
  EndComponent,
  PreviewComponent,

  onCancel,
  onComplete,
}) {

  const [{ step, completed, data }, setDraft] = useDraft(draftId, draftDef, defaultData);

  const handleChange = (_step, _data) => {
    if (_step > 0 || (step > 0 && _step === 0)) {
      setDraft({
        step: _step,
        completed: Math.max(completed, _step),
        data: Object.assign(data, _data)
      }
      );
    }
  }

  return (
    <MultiForm
      defaultValue={data}
      steps={steps}
      initialStep={step}
      completed={completed}
      simple={simple}

      onChange={handleChange}
      onComplete={onComplete}
      onCancel={onCancel}

      DocumentType={DocumentType}
      PreviewComponent={PreviewComponent}
      EndComponent={EndComponent}
    />
  );
}

export default function Designer({
  title,
  subtitle,
  steps,

  defaultData,
  DocumentType,

  simple = false,

  draftId,
  draftDef,

  StartComponent,
  EndComponent,
  PreviewComponent,

  onComplete,
}) {

  const [newDraftId, setNewDraftId] = useState(null);

  const { progress, notify } = useApp();

  const navigate = useNavigate();

  const handleStart = () => {
    const newDraftId = draftDef.prefix + Date.now();
    setNewDraftId(newDraftId);
  }

  const handleCancel = _data => {
    if (draftId) {
      navigate(-1);
      return;
    }
    setNewDraftId(null);
  }

  const handleDiscard = () => {
    deleteDraft(draftId);
    notify("A draft has been deleted", "info");
    navigate(-1);
  }

  return (
    <Box>
      <Box display="flex" alignItems="baseline">
        <PageTitle>{title}</PageTitle>
        <Box flexGrow={1} />
        {(!!draftId || !!newDraftId) && (
          <Button size="small" color="error" startIcon={<Delete />} onClick={handleDiscard} sx={{ whiteSpace: "nowrap"}} >discard draft</Button>
        )}
      </Box>
      {subtitle && (!!draftId || !!newDraftId) && (
        <PageSubtitle>{subtitle}</PageSubtitle>
      )}
      <Box>
        {(!!StartComponent && (!draftId && !newDraftId)) && (
            <StartComponent onStart={handleStart} />
        )}
        {(!!draftId || !!newDraftId) && (
          <DesignerSteps
            defaultData={defaultData}
            draftId={draftId || newDraftId}
            draftDef={draftDef}

            steps={steps}

            simple={simple}

            onComplete={onComplete}
            onCancel={handleCancel}

            DocumentType={DocumentType}
            EndComponent={EndComponent}
            PreviewComponent={PreviewComponent}
          />
        )}
      </Box>
      <PageEnd />
    </Box>
  );
}
