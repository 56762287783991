import { Visibility, VisibilityOff } from "@mui/icons-material";
import PasswordIcon from '@mui/icons-material/Password';
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { forwardRef, useState } from "react";

export const PasswordField = forwardRef(({
  helperText,
  error = false,
  ...props
}, ref) => {

  const [visible, setVisible] = useState(false);

  return (
    <TextField
      type={visible ? 'text' : 'password'}
      // onChange={(e) => onChange(e.target.value)}
      error={!!error}
      helperText={!!error ? error.message : helperText}
      inputRef={ref}
      InputProps={{
        startAdornment: <InputAdornment position="start"><PasswordIcon /></InputAdornment>,
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setVisible(!visible)}
              onMouseDown={(e) => e.preventDefault()}
              edge="end"
            >
              {visible ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        )
      }}
      {...props}
    />
  );
});