import { Link, NavigateNext, Public, Repeat, Security, Task } from "@mui/icons-material";
import { Box, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Stack, Typography } from "@mui/material";


const TYPES = [
    {
        "name": "Show details",
        "code": "PUBLIC",
        "subtitle": "Anyone"
    },
    {
        "name": "Design another one",
        "code": "LINK",
        "subtitle": "Anyone with a link"
    },
    {
        "name": "Complete [Claim,VC, ...] design - only visible when started from claim,vc designer",
        "code": "PRIVATE",
        "subtitle": "Just me"
    }
]

const accessIcon = {
    "LINK": Repeat,
    "PUBLIC": NavigateNext,
    "PRIVATE": Task,
}


export function NextAction({ }) {
    return (
        <Box>
            <Box>
                <FormControl disabled>
                    <FormLabel id="demo-controlled-radio-buttons-group">[TODO] Next Action</FormLabel>
                    <RadioGroup
                        name="access"
                        value="PUBLIC"

                    >
                        {TYPES.map((accessType) => {

                            const RadioIcon = accessIcon[accessType.code];

                            return (
                                <FormControlLabel key={accessType.code} value={accessType.code}
                                    control={<Radio />}
                                    label={(<Stack direction="row" spacing={2} alignItems="center" m={1}>
                                        <RadioIcon sxx={{ fontSize: 40, color: "#4a4a4a", }} />

                                            <Typography>{accessType.name}</Typography>
                                    
                                    </Stack>)}
                                />
                            )
                        })}
                    </RadioGroup>
                </FormControl>
            </Box>
        </Box>
    );
}