import { FormPanel } from "component/element/Layout";
import { MultiFormStep } from "component/element/MultiForm";
import { NextAction } from "component/element/NextAction";
import PrevNextButtons from "component/element/PrevNextButtons";
import ProtectedButton from "component/element/ProtectedButton";
import { EntityOverview } from "../EntityView";

export function StepEntityReview({ value, onPrev, onNext, mode = "edit" }) {

    return (
        <MultiFormStep
            label="Review"
        >
            <FormPanel>
                <EntityOverview entity={value} m={t => t.spacing(0, 1, 2, 1)} />
            </FormPanel>
            <PrevNextButtons
                mt={2}
                mb={1}
                onPrev={onPrev}
                nextLabel="Create"
                NextComponent={ProtectedButton}
                onNext={onNext}
                disabled={mode !== "edit"}
            />

            <FormPanel mt={2}>
                <NextAction />
            </FormPanel>
        </MultiFormStep>
    );
}