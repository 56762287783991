import { FormPanel } from 'component/element/Layout';
import { EntityOverview } from 'component/entity/EntityView';
import { EntitySelector } from 'component/selector/EntitySelector';
import * as yup from 'yup';

export function StepSubjectDetail({ value }) {

  if (!value) {
    return "Select a subject to display details"
  }

  return (<EntityOverview entity={value} verbs={false} />);
}

StepSubject.Schema = yup.object({
  term: yup.string().trim().required().min(2),
  vocab: yup.string().trim().required().url(),
}).required();

export function StepSubject({ defaultValue, error = false, onChange = () => { }, onSubmit = () => { } }) {

  return (
    <FormPanel>
      <EntitySelector defaultValue={defaultValue} error={error} onChange={onChange} onSubmit={onSubmit} />
    </FormPanel>
  );
}