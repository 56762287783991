import { Add } from "@mui/icons-material";
import { Box, Button, Divider, Skeleton, Stack, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { CLAIM_DRAFT_KEY_PREFIX } from "component/claim/ClaimTypeDesigner";
import { SelectableCardView } from "component/element/CardView";
import { createDraft } from "component/element/Drafts";
import { findSubjectClaims } from "document/claimService";
import { useApp } from "lib/app";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export function ClaimsSelector({ subject, selected, onAdd, onCancel }) {

    const [checked, setChecked] = useState([]);

    const { progress } = useApp();
    const [claims, setClaims] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        const fetchClaims = async (path) => {
            try {
                progress(true);

                let docs = await findSubjectClaims(path);

                if (selected) {
                    docs = docs.filter(doc => !selected.includes(doc.ref.path));
                }

                setClaims(docs);
                progress(false);

            } catch (e) {
                console.log(e);
            }
        }
        fetchClaims(subject.ref.path);
    }, []);


    const handleNewClaim = () => {
        const cloneId = Date.now();
        createDraft(CLAIM_DRAFT_KEY_PREFIX + cloneId, { subject, object: { type: "attribute" }, }, 1, 1);
        navigate(`/claim/draft/` + cloneId);
    }

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const handleAdd = () => {
        onAdd && onAdd(claims.filter(claim => checked.includes(claim.ref.path)));
    }

    return (
        <Box sx={{
            borderBottomRightRadius: "5px",
            borderBottomLeftRadius: "5px",
            // bgcolor: blueGrey[50]
        }}>
            <Box
                display="flex"
                alignItems="baseline"
                p={theme => theme.spacing(1.5, 2, 0, 2)}
            >
            <Typography
                sx={{
                    flexGrow: 1,
                    fontFamily: 'Montserrat, sans-serif',
                    fontSize: 12,
                    textTransform: "uppercase",
                    letterSpacing: 1,
                }}
            >
                Claims
            </Typography>
            <Box flexGrow={0}>
                <Button 
                    size="small" 
                    color="secondary"
                    startIcon={<Add />}
                    onClick={handleNewClaim}
                    >New Claim</Button>
            </Box>
        </Box>
       
            {
        claims && claims.map((claim, _i) => (
            <Box key={claim.ref.path + _i}>
                {_i > 0 && (<Divider variant="inset" />)}

                <SelectableCardView
                    title={claim.toString()}
                    subtitle={claim.object.toString()}
                    description={claim.description}
                    uri={claim.uri()}
                    // value={claim.ref.path}
                    selected={checked.includes(claim.ref.path)}
                    onClick={handleToggle(claim.ref.path)}
                />
            </Box>
        ))
    }
    {
        (!claims) && [...Array(1)].map((_, index) => (
            <Skeleton key={index} variant="rectangular" animation="wave" height={70} sx={{
                m: t => t.spacing(0, 0, 1, 0),
            }} />
        ))
    }
    {
        claims && claims.length === 0 && (
            <Typography
                sx={{
                    fontFamily: 'Montserrat, sans-serif',
                    p: t => t.spacing(2, 2, 2, 4),
                }}
            >No claims to add</Typography>
        )
    }

    <Stack spacing={1} direction="row" mt={1}
        sx={{
            p: 1,
            // bgcolor: blueGrey[50],
            borderBottomRightRadius: "5px",
            borderBottomLeftRadius: "5px",
        }}
    >
        <Button
            fullWidth
            disableElevation
            size="large"
            onClick={() => onCancel()}
            sx={{
                // color: grey[100],
            }}
        >
            Cancel
        </Button>
        <Button
            fullWidth
            variant="contained"
            disableElevation
            size="large"
            disabled={checked.length === 0}
            color="secondary"
            sx={{
                color: grey[100],
                // bgcolor: orange.A700,
            }}
            onClick={handleAdd}
        >
            Add Selected
        </Button>
    </Stack>
        </Box >
    );
}
