import { Checkbox, FormControlLabel, FormGroup, FormHelperText } from '@mui/material';
import { AccessSelector } from 'component/element/AccessSelector';
import { FormPanel } from 'component/element/Layout';


StepEntityScope.Controlled = true;

export function StepEntityScope({ defaultValue, onChange = () => { } }) {

  const handleSubjectChange = event => {
    onChange({ scope: { credentialSubject: event.target.checked } });
  }

  return (<>
    <FormPanel>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              defaultChecked={defaultValue?.scope?.credentialSubject}
              onChange={handleSubjectChange}
            />}
          label="The entity is a verifiable credential subject."
        />
        <FormHelperText>a verifiable credentials can be issued to the entity.</FormHelperText>
      </FormGroup>
    </FormPanel>

    <FormPanel mt={2}>
      <AccessSelector />
    </FormPanel>
  </>);
}