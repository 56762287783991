import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, getFirestore, limit, query, Timestamp, where } from "firebase/firestore";
import { ClaimDocument, ObjectDocument } from "./claim";
import { EntityDocument } from "./entity";
import { entityConverter } from "./entityService";
import { removeEmptyValues } from "./utils";

const db = getFirestore();

export function claimsFromFirestore(documentRef, document) {

    const {
        subject: { link: subjectRef, modified: subjectModified, ...subject },
        object: { type: objectType, ...object },
        modified,
        ...data
    } = document;

    let wrappedObject;

    if (objectType === "entity") {

        const { link: entityRef, modified: entityModifed, ...entity } = object;

        const ref = entityRef
            ? { id: entityRef.id, path: entityRef.path }
            : null;

        wrappedObject = {
            entity: Object.assign(new EntityDocument(), {
                ref,
                modified: entityModifed ? entityModifed.toDate() : null,
                ...entity,
            }),
            type: "entity",
        };

    } else {
        wrappedObject = {
            attribute: object,
            type: "attribute",
        }
    }

    const ref = subjectRef
        ? { id: subjectRef.id, path: subjectRef.path }
        : null;

    return Object.assign(new ClaimDocument(),
        {
            ref: { id: documentRef.id, path: documentRef.path },
            subject: Object.assign(new EntityDocument(), {
                ref,
                modified: subjectModified ? subjectModified.toDate() : null,
                ...subject
            }),
            object: Object.assign(new ObjectDocument(), wrappedObject),
            modified: modified ? modified.toDate() : null,
            ...data,
        }
    );
}

// Firestore data converter
export const claimConverter = {
    toFirestore: ({ ref, ...entity }) => {

        const {
            subject: { ref: subjectRef, verb, scope, ...subject },
            object: { type: objectType, ...wrappedObject },
            modified,
            ...data
        } = entity;

        let object;

        if (objectType === "entity") {

            const { entity: { ref: entityRef, verb, scope, ...entity } } = wrappedObject;

            object = {
                ...entityConverter(ObjectDocument).toFirestore(entity),
                link: doc(db, entityRef.path),
                type: "entity",
            };

        } else {

            const { attribute } = wrappedObject;

            object = {
                ...attribute,
                type: "attribute",
            }
        }

        return removeEmptyValues({
            subject: {
                ...entityConverter(EntityDocument).toFirestore(subject),
                link: doc(db, subjectRef.path),
            },
            object,
            modified: modified ? Timestamp.fromDate(modified) : null,
            ...data
        });
    },

    fromFirestore: (snapshot, options) => {
        return claimsFromFirestore(snapshot.ref, snapshot.data(options));
    }
};

const collectionRef = collection(db, "claim").withConverter(claimConverter);

const allClaimsQuery = query(collectionRef, limit(100));

export async function getAllClaims() {
    return getDocs(allClaimsQuery).then(snap => snap.docs.map(doc => doc.data()));
}

export async function createClaim(claim) {
    return await addDoc(collectionRef, {
        ...claim,
        modified: new Date(),
    });
}

export async function getClaim(path) {
    return getDoc(doc(db, path).withConverter(claimConverter));
}

export async function deleteClaim(id) {
    return await deleteDoc(doc(db, "claim", id));
}

export async function findSubjectClaims(path) {
    return getDocs(query(collectionRef, where("subject.link", "==", doc(db, path)), limit(100)))
        .then(snap => snap.docs.map(doc => doc.data()));
}


