import { useCallback, useState } from "react";

export const useSessionStorage = (key, initialValue, converter, expiration) => {
  return useStorage(typeof window !== "undefined" && window.sessionStorage, key, initialValue, converter, expiration);
}

export const useLocalStorage = (key, initialValue, converter, expiration) => {
  return useStorage(typeof window !== "undefined" && window.localStorage, key, initialValue, converter, expiration);
}

function useStorage(storage, key, initialValue = null, converter = null, expiration = null) {

  const [value, setValue] = useState(
    () => {
      try {

        return getStorageItem(storage, key, converter) || initialValue;

      } catch (error) {
        console.error(error);
      }

      return initialValue;
    });

  const _setValue = useCallback(_value => {
    try {
      setValue(_value);
      setStorageItem(storage, key, _value, expiration);

    } catch (error) {
      console.error(error);
    }

  }, [expiration, key, storage]);

  return [value, _setValue];
}

export function getStorageItem(storage, key, converter) {

  const jsonValue = storage && storage.getItem(key);

  if (jsonValue) {
    const container = JSON.parse(jsonValue);
    if (!container.expires || container.expires > Date.now()) {
      return converter?.fromJson ? converter.fromJson(container.data) : container.data;
    }

    if (container.expires < Date.now()) {
      storage.removeItem(key);
    }
  }
}

export function setStorageItem(storage, key, value, expiration = null) {

  const data = { data: value, modified: Date.now() };

  if (expiration) {
    data.expires = (Date.now() + (expiration * 1000));
  }

  storage && storage.setItem(key, JSON.stringify(data));
}

export function removeStorageItem(storage, key) {
  storage.removeItem(key);
}
