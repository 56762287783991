import { Button } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useUser } from '../../lib/user';
import { useSplashScreen } from "./SplashScreen";

function LoginButton({ children,  ...props }) {

    const [,setScreen] = useSplashScreen();

    return (
        <Button
            {...props}
            onClick={() => setScreen("login")}
        >
            Login To {children}
        </Button>
    );
}

export default function ProtectedButton({ children, onClick, ...props }) {
    const { user } = useUser();
    if (user) {
        return (<Button {...props} disableElevation onClick={onClick}>{children}</Button>);
    }
    return (<LoginButton {...props} disableElevation>{children}</LoginButton>);
}