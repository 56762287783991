import Designer from 'component/element/Designer';
import { deleteDraft } from 'component/element/Drafts';
import { StepDescription } from 'component/step/StepDescription';
import { BackCamelIdSchema, StepId } from 'component/step/StepId';
import { StepObject, StepObjectDetail } from 'component/step/StepObject';
import { StepStatement } from 'component/step/StepStatement';
import { StepSubject, StepSubjectDetail } from 'component/step/StepSubject';
import { ClaimDocument, claimJsonConverter, ObjectDocument } from 'document/claim';
import { createClaim } from 'document/claimService';
import { designConverter } from 'document/design';
import { EntityDocument } from 'document/entity';
import { useApp } from 'lib/app';
import { useNavigate, useParams } from 'react-router-dom';
import ClaimTypePreview from './ClaimTypePreview';
import ClaimTypeStart from './ClaimTypeStart';
import StepClaimTypeReview from './step/StepClaimTypeReview';

const steps = [
  {
    id: "subject",
    control: StepSubject,
    label: "Subject",
    description: "Select one subject type the claim type is credited to.",
    detail: {
      label: "Subject Details",
      Component: StepSubjectDetail,
    },
  },
  {
    id: "object",
    control: StepObject,
    label: "Object",
    description:
      `Define an object type the subject has a relation to. An object represents what is credited to a subject.`,
    detail: {
      label: "Object Details",
      Component: StepObjectDetail,
      filter: (object) => object?.type === "entity",
    },
  },
  {
    id: "rel",
    key: ["verb", "name"],
    control: StepStatement,
    label: "Statement",
    description:
      `Name the relation between the subject and the object.`,
  },
  {
    id: "id",
    key: ["term", "vocab"],
    control: StepId,
    schema: BackCamelIdSchema,
    param: {
      syntax: "backCamel",
    },
    label: "Identifier",
  },
  {
    id: "description",
    control: StepDescription,
    label: "Description",
    description:
      `Please describe the claim type.`,
  },
];

export const CLAIM_DRAFT_KEY_PREFIX = "claim-draft-";

export const CLAIM_DRAFT_DEF = {
  prefix: CLAIM_DRAFT_KEY_PREFIX,
  toUrl: (id) => `/claim/draft/${id}`,
  label: (draft) => `${draft.data.subject?.article || ""} ${draft.data.subject?.name?.toLowerCase() || ""} claim`.trim(),
  converter: designConverter(claimJsonConverter),
}

const DEFAULT_CLAIM = Object.assign(new ClaimDocument(), {
  subject: new EntityDocument(),
  object: Object.assign(new ObjectDocument(), {
    entity: new EntityDocument(0,)
  }),

});

export default function ClaimTypeDesigner() {

  const { draftId } = useParams();

  const { progress, notify } = useApp()

  const navigage = useNavigate();

  const handleComplete = (data) => {
    progress(true);
    createClaim(data)
      .then((ref) => {
        notify("A new claim has been created.");
        deleteDraft(CLAIM_DRAFT_KEY_PREFIX + draftId);
        navigage(`/claim/${ref.id}`);
      })
      .catch((e) => notify(e, "error"))
      .then(() => progress(false))
  }

  return (
    <Designer
      title={draftId ? "New Claim Type" : "New Claim Type"}
      subtitle={"A claim is subject-verb-object statement crediting the subject with an attribute or with a relation to an entity."}

      draftId={draftId ? CLAIM_DRAFT_KEY_PREFIX + draftId : null}
      draftDef={CLAIM_DRAFT_DEF}

      steps={steps}
      
      defaultData={new ClaimDocument()}
      
      DocumentType={ClaimDocument}

      StartComponent={ClaimTypeStart}
      PreviewComponent={ClaimTypePreview}
      EndComponent={StepClaimTypeReview}

      onComplete={handleComplete}
    />
  );
}
