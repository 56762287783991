import { TextField } from '@mui/material';
import * as yup from 'yup';
import { FormPanel } from '../element/Layout';

StepName.Schema = yup.string().required().trim().min(4);

export function StepName({
  defaultValue = "",
  error = false,
  onSubmit = () => { },
  onChange = () => { }
}) {

  return (
    <FormPanel>
        <TextField
          defaultValue={defaultValue || ""}
          fullWidth
          name="name"
          label="Name"
          variant='standard'
          error={!!error}
          helperText={error || ""}
          onBlur={e => onChange(e.target.value)}
          onKeyPress={(ev) => {
            if (ev.key === 'Enter') {
              ev.preventDefault();
              onSubmit(ev.target.value);
            }
          }}
        />
    </FormPanel>
  );
};
