import { Autocomplete, FormControl, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material';
import { FormPanel } from 'component/element/Layout';
import { useMemo, useState } from 'react';
import * as yup from 'yup';

StepStatement.Schema = yup.object({
  verb: yup.string().trim().required().min(2),
  name: yup.string().trim().matches(/^[a-zA-Z\s]*$/, 'only letters and spaces allowed')
    .when("verb", {
      is: verb => ["is", "has", "has been"].includes(verb),
      then: yup.string().trim().min(2).required(),
    })
}).required();

export function StepStatement({
  defaultValue: { verb: defaultVerb, name: defaultName },
  data: { subject, object, vocab },
  error = false,
  onChange = () => { },
  onSubmit = () => { },
}) {

  const [verb, setVerb] = useState(defaultVerb || "is");

  const handleNameChange = event => {
    onChange({ name: event.target.value.trim(), vocab: vocab || (["is", "has", "has been"].includes(verb) ? null : "https://schema.org/") });
  };

  const handleNameSubmit = event => {
    onSubmit({ name: event.target.value.trim(), vocab: vocab || (["is", "has", "has been"].includes(verb) ? null : "https://schema.org/") });
  };

  const handleVerbChange = (event) => {
    onChange({ verb: event.target.value });
    setVerb(event.target.value);
  }

  const isAttribute = object.isAttribute() || false;

  const verbs = useMemo(() => {
    const verbs = new Set();
    verbs.add("is");
    verbs.add("has");
    verbs.add("has been");

    if (!isAttribute) {
      subject.verb.forEach(verbs.add, verbs);
    }

    return verbs;
  }, [isAttribute]);

  return (
    <FormPanel>
      <Stack direction="row" spacing={1}>
        <FormControl sx={{ width: 250 }} variant="standard">
          <InputLabel id="verb-select-label">Verb</InputLabel>
          <Select
            labelId="verb-select-label"
            id="verb-select"
            value={verb}
            name="verb"
            size="large"
            inputProps={{ sx: { backgroundColor: "#fff" } }}
            label="Verb"
            onChange={handleVerbChange}
          >
            {[...verbs].map(_verb => (
              <MenuItem value={_verb} key={_verb}>{_verb}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <Autocomplete
          fullWidth
          freeSolo
          autoFocus
          disableClearable
          options={STATEMENTS.map((option) => option.title)}
          defaultValue={defaultName || ""}
          onBlur={e => handleNameChange(e)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              handleNameSubmit(e);
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              name="name"
              label={isAttribute ? "Attribute Name" : (["is", "has", "has been"].includes(verb) ? "Relation Name" : "Relation Suffix (optional)")}
              error={!!error?.name}
              required={["is", "has", "has been"].includes(verb)}
              helperText={!!error?.name ? error?.name : ""}
              InputProps={{
                ...params.InputProps,
                type: 'search',
              }}
            />
          )}
        />
      </Stack>
    </FormPanel>
  );
}

const STATEMENTS = [
  { title: '[TODO: suggestions]' },
];