import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, getFirestore, limit, query, Timestamp, where } from "firebase/firestore";
import { EntityDocument } from "./entity";
import { removeEmptyValues } from "./utils";

const db = getFirestore();

// Firestore data converter
export const entityConverter = (DocumentType) => ({
    toFirestore: ({ref, modified, ...entity}) => {
        return removeEmptyValues({
            ...entity,
            modified: modified ? Timestamp.fromDate(modified) : null,
        });
    },
    fromFirestore: (snapshot, options) => {
        const { modified, ...data } = snapshot.data(options);

        return Object.assign(new DocumentType(),
            {
                ref: { id: snapshot.id, path: snapshot.ref.path },
                modified: modified ? modified.toDate() : null,
                ...data,
            }
        );
    }
});

const collectionRef = collection(db, "entity").withConverter(entityConverter(EntityDocument));

const allEntitiesQuery = query(collectionRef, limit(100));

const allCredentialSubjectsQuery = query(collectionRef, where("scope.credentialSubject", "==", true), limit(100));

export async function getAllCredentialSubjects() {
    return getDocs(allCredentialSubjectsQuery);
}

export async function getAllEntities() {
    return await getDocs(allEntitiesQuery);
}

export async function createEntity(entity) {
    return await addDoc(collectionRef, {
        ...entity,
        modified: new Date(),
    });
}

export async function deleteEntity(id) {
    return await deleteDoc(doc(db, "entity", id));
}

export async function getEntity(path) {
    return getDoc(doc(db, path).withConverter(entityConverter(EntityDocument)));
}

export async function getEntityAttrs(path) {
    //TODO
}