import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { FormPanel } from "component/element/Layout";
import { MultiFormStep } from "component/element/MultiForm";
import { NextAction } from "component/element/NextAction";
import PrevNextButtons from "component/element/PrevNextButtons";
import ProtectedButton from "component/element/ProtectedButton";

function Line({ mt, label, value }) {
    return (
        <Box mt={mt}>
            <Typography variant="body2" component="span" sx={{ pr: 1, fontWeight: 700, }}>
                {label}:
            </Typography>
            <Typography component="span" sx={{ fontSize: 16 }}>
                {value}
            </Typography>
        </Box>
    )
}

function ClaimOverview({ claim, ...params }) {
    return (<Box {...params}>
        <Typography variant="h5" gutterBottom>{claim?.toString()}</Typography>
        <Typography variant="body1">
            {claim.description}
        </Typography>
        <Box mt={2}>
            <Line label="Term" value={claim.term} />
            <Line label="Vocabulary" value={claim.vocab} />
        </Box>
        <Box mt={3}>
            <Typography sx={{ fontSize: 16, mb: .5, fontWeight: 400, textTransform: "uppercase" }}>Subject</Typography>
            <Box ml={1}>
                <Line label="Name" value={claim.subject.toString()} />
                <Line label="Term" value={claim.subject.term} />
                <Line label="Vocabulary" value={claim.subject.vocab} />
            </Box>
        </Box>
        {claim.object.isRel() && (
            <Box mt={3}>
                <Typography sx={{ fontSize: 16, mb: .5, fontWeight: 400, textTransform: "uppercase" }}>Object</Typography>
                <Box ml={1}>
                    <Line label="Name" value={claim.object.toString()} />
                    <Line label="Term" value={claim.object.entity.term} />
                    <Line label="Vocabulary" value={claim.object.entity.vocab} />
                </Box>
            </Box>
        )}
        {claim.object.isAttribute() && (
            <Box mt={3}>
                <Typography sx={{ fontSize: 16, mb: .5, fontWeight: 400, textTransform: "uppercase" }}>Attribute</Typography>
                <Box ml={1}>
                    <Line label="Value" value={claim.object.toString()} />
                </Box>
            </Box>
        )}
    </Box>);
}


export default function StepClaimTypeReview({ value, onPrev, onNext, mode = "edit" }) {

    return (
        <MultiFormStep
            label="Review"
        >
            <FormPanel>
                <ClaimOverview claim={value} m={t => t.spacing(0, 1, 2, 1)} />
            </FormPanel>

            <PrevNextButtons
                mt={2}
                mb={1}
                onPrev={onPrev}
                nextLabel="Create"
                NextComponent={ProtectedButton}
                onNext={onNext}
                disabled={mode !== "edit"}
            />

            <FormPanel mt={2}>
                <NextAction />
            </FormPanel>

        </MultiFormStep>
    );
}