import { FormControl, FormControlLabel, Radio, RadioGroup, Stack, Typography } from "@mui/material";
import { useState } from "react";

export default function NumberConstraintForm({ 
    defaultValue = [], 
//    onChange = () => {} 
}) {

    const [{type, range}, setConstraint] = useState({
        type: defaultValue.includes("decimal") ? "decimal" : "integer",
        range: defaultValue.includes("negative") ? "negative" : defaultValue.includes("positive") ? "positive" : "",
    });

    const handleTypeChange = (_type) => {
        if (_type === "decimal") {
            setConstraint({ type: _type, range: "" });
            return;
        }
        setConstraint({ type: _type, range });
    }

    const handleRangeChange = (_range) => {
        setConstraint({ type, range: _range });
    }

    return (<>
        <Typography variant="subtitle2" sx={{ fontWeight: 400 }}>Constraints</Typography>
        <Stack direction="row" spacing={1}>
            <NumberType value={type} onChange={handleTypeChange} />
            <NumberRange value={range} disabled={type === "decimal"} onChange={handleRangeChange} />
        </Stack>
    </>);
}

function NumberType({ value = "integer", onChange }) {
    return (
        <FormControl fullWidth>
            <RadioGroup
                value={value}
                name="attribute.constraint[0]"   //FIXME customuze the name
                onChange={event => onChange && onChange(event.target.value)}
            >
                <FormControlLabel value="integer" control={<Radio />} label="Integer" />
                <FormControlLabel value="decimal" control={<Radio />} label="Decimal" />
            </RadioGroup>
        </FormControl>
    )
}

function NumberRange({ value = "", disabled = false, onChange }) {
    return (
        <FormControl fullWidth disabled={disabled}>
            <RadioGroup
                value={value}
                name="attribute.constraint[1]"   //FIXME customuze the name
                onChange={event => onChange && onChange(event.target.value)}
            >
                <FormControlLabel value="" control={<Radio />} label="Any" />
                <FormControlLabel value="positive" control={<Radio />} label="Positive (>0)" />
                <FormControlLabel value="negative" control={<Radio />} label="Negative (<0)" />
            </RadioGroup>
        </FormControl>
    )
}