import { Source } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import { amber, blueGrey, grey, orange } from '@mui/material/colors';
import { ActionPanel } from 'component/Dashboard';
import { PageSubtitle } from 'component/element/Layout';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { DraftCardActions, useDrafts } from '../element/Drafts';
import ButtonLink from '../element/Link';
import { ENTITY_DRAFT_DEF } from './EntityTypeDesigner';


export default function EntityTypeStart({ onStart }) {

  const { drafts, deleteDraft } = useDrafts(ENTITY_DRAFT_DEF);

  const navigate = useNavigate();

  const handleNext = () => {
    onStart && onStart();
  };

  return (
    <>
      <PageSubtitle>
        An entity represents an identifiable structured object.
      </PageSubtitle>
      <Box p={{ xs: 0, sm: 0 }} mt={{ xs: 1, sm: 1, md: 2 }}>
          
      <Button
        onClick={handleNext}
        size="large"
        variant='contained'
        sx={{ p: t => t.spacing(2, 6, 2, 6), m: 2, mb: 4, fontSize: 18, fontWeight: 700 }}
        disableElevation
      >
        Design new entity type
      </Button>

      {(drafts?.length > 0) && (
        <ActionPanel label="Drafts" content={drafts
          .sort((a, b) => b.modified.getTime() - a.modified.getTime())
          .map(draft => ({
            title: `${draft.label}`,
            Content: () => (
              <Box sx={{ width: "100%" }}>
                <Box sx={{ fontSize: 15, mb: 0.5, mt: 0.5 }} >{`Modified  ${moment(draft.modified).startOf().fromNow()}`}</Box>
                <Box sx={{ fontSize: 12, color: "text.secondary" }}>{`Created  ${moment(draft.created).format("ddd MMM D, h:MM a")}`}</Box>
              </Box>),
            icon: props => <Source {...props} />,
            color: grey[50],
            bgcolor: amber[300],
            actions: (<DraftCardActions onDelete={deleteDraft} id={draft.id} key={draft.id} />),
            url: draft.url,
            id: draft.id,
          }))} navigate={navigate} />
      )}

      <Typography
        sx={{
          fontSize: 15,
          fontWeight: 500,
          textTransform: "uppercase",
          fontFamily: 'Montserrat, sans-serif',
          color: "text.secondary",
          mt: 3,
        }}>
        Examples
      </Typography>
      <Box ml={1}>
        <ButtonLink to="/entity/">Explore Entity Directory </ButtonLink> 
        <Typography component="span" sx={{ fontFamily: 'Montserrat, sans-serif' }}>
        and start by cloning an existing entity
        </Typography>
      </Box>
      </Box>
    </>
  );
}
