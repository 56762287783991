import { Box, styled } from "@mui/material";
import { blueGrey, grey, lightBlue } from "@mui/material/colors";

//TODO replace with Page|Document
export const PageTitle = styled('div')(({ theme }) => ({
    fontWeight: 800,
    fontSize: 32,
    fontFamily: 'Montserrat, sans-serif',
    // letterSpacing: 1,
    color: grey[700],

    [theme.breakpoints.only('xs')]: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(0),
        //        marginBottom: theme.spacing(1),
    },
    [theme.breakpoints.only('sm')]: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(0),
        //      marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.only('md')]: {
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(0),
        //    marginBottom: theme.spacing(3),
    },
    [theme.breakpoints.up('lg')]: {
        paddingTop: theme.spacing(6),
        paddingBottom: theme.spacing(0),
        //    marginBottom: theme.spacing(3),
    },
}));


export const PageSubtitle = styled(Box)(({ theme }) => ({
    fontWeight: 400,
    fontSize: 16,
    fontFamily: 'Montserrat, sans-serif',
    color: grey[700],
    padding: theme.spacing(0, 1, 0, .2),
    marginBottom: theme.spacing(2),
}));



const PageEndOutter = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(3),
}));

const PageEndInner = styled('div')(({ theme }) => ({
    width: "75%",
    // borderBottom: `1px solid ${grey[300]}`,
    height: "auto",
    margin: "0 auto",
    position: "relative",
}));

export const PageEnd = (props) => (<PageEndOutter {...props}><PageEndInner /></PageEndOutter>)

export const MainHeader = styled("div")(({ theme }) => ({
    fontWeight: 700,
    fontSize: 22,
    padding: theme.spacing(0, 0, 0, 1),
    fontFamily: 'Montserrat, sans-serif',
    letterSpacing: 1,
    color: grey[100],
    // color: "black",
    margin: theme.spacing(0, 0, 1, 0),
    [theme.breakpoints.only('xs')]: {
        // paddingBottom: theme.spacing(.5),
        // marginBottom: theme.spacing(1),
    },
    [theme.breakpoints.up('sm')]: {
        // paddingBottom: theme.spacing(1),
        // marginBottom: theme.spacing(2),
    },
}));

export const SectionHeader = styled("div")(({ theme }) => ({
    fontWeight: 700,
    fontSize: 14,
    textTransform: "uppercase",
    padding: theme.spacing(0, 0, 1, 1),
    margin: theme.spacing(0, 0, 1, 0),
    letterSpacing: 1,
    // color: amber[700],
    fontFamily: 'Montserrat, sans-serif',
    // borderBottom: `1px solid ${grey[300]}`,

    [theme.breakpoints.only('xs')]: {
        // paddingBottom: theme.spacing(.5),
        // marginBottom: theme.spacing(1),
    },
    [theme.breakpoints.up('sm')]: {
        // paddingBottom: theme.spacing(1),
        // marginBottom: theme.spacing(2),
    },
}));

export const SecondaryHeader = styled("div")(({ theme }) => ({
    fontWeight: 700,
    fontSize: 14,
    textTransform: "uppercase",
    padding: theme.spacing(0, 0, 1, 1),
    margin: theme.spacing(0, 0, 1, 0),
    letterSpacing: 1,
    // color: lightBlue[600],
    fontFamily: 'Montserrat, sans-serif',
}));

export const Main = styled(Box)(({ theme }) => ({
    backgroundColor: blueGrey[800],
    padding: theme.spacing(2, 1, 1, 1),
    borderRadius: 7,
    // color: grey[50],
    // backgroundColor: lime[100],
    // borderColor: lime[50],
    // backgroundColor: teal[600],
    // borderColor: teal[800],
    backgroundColor: lightBlue[600],
    borderColor: lightBlue[500],

    borderWidth: 1,
    borderStyle: "solid",
    color: "white",
    //  color: "black",
}));

export const Panel = styled(Box)(({ theme }) => ({
    // backgroundColor: teal[50],
    padding: theme.spacing(2, 0, 0, 0),
    // borderRadius: 5,
    // backgroundColor: blueGrey[50],
    // borderColor: teal[100],
    // borderStyle: "solid",
    // borderWidth: 2,
    // backgroundColor: alpha(blueGrey[50], .5),
    // backgroundColor: alpha(lime[50], .5),    
    // borderColor: amber[200],    
    // borderStyle: "solid",
    // borderWidth: 1,
}));

export const Secondary = styled(Box)(({ theme }) => ({
    padding: theme.spacing(2, 0, 0, 0),
    // backgroundColor: blueGrey[600],
    // borderRadius: 5,
    // color: grey[50],
    // backgroundColor: alpha(lightBlue[50], .5),
    // borderColor: lightBlue[200],

    // borderWidth: 1,
    // borderStyle: "solid",
    // color: "black",

}));

export const PanelContent = styled(Box)(({ theme }) => ({
    padding: theme.spacing(0, 1, 1, 1),
}));

export const FormPanel = styled(Box)(({ theme }) => ({
    backgroundColor: "#fff",
    color: "#000",
    borderRadius: 5,
    padding: theme.spacing(2, 2, 2, 2),
}))

export const Form = styled("form")(({ theme }) => ({
    backgroundColor: "#fff",
    color: "#000",
    borderRadius: 5,
    padding: theme.spacing(2, 2, 2, 2),
}))

