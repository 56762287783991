import { Box, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import NumberConstraintForm from 'component/selector/NumberConstraintForm';
import { attributeTypes } from 'resources/attr-types';
import { constantTypes } from 'resources/constant-type';
import * as yup from 'yup';



export function AttributeSelector({
    defaultValue,
    error = false,
    onChange = () => { },
}) {

    const handleTypeChange = (id) => {
        onChange && onChange({ id });
    }

    return (
        <>
            <Box sx={{ p: theme => theme.spacing(1, 0, 1, 0) }}>
                <AttributeType value={defaultValue?.id} onChange={handleTypeChange} />
            </Box>

            {defaultValue?.id === "number" && (
                <Box sx={{ p: theme => theme.spacing(1, 0, 1, 0) }}>
                    <NumberConstraintForm defaultValue={defaultValue?.constraint} />
                </Box>
            )}
        </>
    )
}


function AttributeType({ value, onChange, sx, label = "Type", options = attributeTypes }) {

    return (
        <FormControl fullWidth sx={sx} variant="standard">
            <InputLabel id="attr-type-select-label">{label}</InputLabel>
            <Select
                labelId="attr-type-select-label"
                id="attr-type-select"
                name="attribute.id"
                value={value || "text"}
                label={label}
                onChange={event => onChange && onChange(event.target.value)}
            >
                {options.map((type) => (
                    <MenuItem key={type.id} value={type.id}>{type.name}</MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

const validators = {
    text: yup.string().trim().min(1),
    number: yup.number(),
    did: yup.string().trim().url(),
    url: yup.string().trim().url(),
}

function validateConstant(type, value) {

    const validator = validators[type] || validators.text;

    if (!!validator) {
        return validator.required().validate(value);
    }
    return true;
}

function AttributeValue({ value, onChange, error }) {

    return (
        <TextField
            label="Value"
            variant='standard'
            fullWidth
            error={!!error}
            helperText={!error ? null : error}
        />
    )
}

function Constant({ value, onChange, error }) {

    const handleTypeChange = (id) => {
        if (id === "true" || id === "false") {
            onChange && onChange({ id: "bool", value: id });
            return;
        }
        onChange && onChange({ id, value: null });
    }

    const handleValueChange = (value) => {
        onChange && onChange({ value });
    }

    return (
        <>
            <Box sx={{ p: theme => theme.spacing(1, 0, 1, 0) }}>
                <AttributeType
                    value={value?.id === "bool" ? value?.value : value?.id || "text"}
                    onChange={handleTypeChange}
                    label="Type"
                    options={constantTypes}
                />
            </Box>

            {value?.id !== "bool" && (
                <Box sx={{ p: theme => theme.spacing(1, 0, 1, 0) }}>
                    <AttributeValue value={value?.value} onChange={handleValueChange} error={error} />
                </Box>
            )}
        </>
    )
}

function AttributeConstraint({ options, value, onChange, sx }) {

    let constraint = value;

    if (Array.isArray(value)) {
        constraint = value[0];
    }

    return (
        <>
            <FormControl fullWidth sx={sx} variant="standard">
                <InputLabel id="attr-constraint-select-label">Constraint</InputLabel>
                <Select
                    labelId="attr-constraint-select-label"
                    id="attr-constraint-select"
                    value={constraint || "none"}
                    label="Constraint"
                    onChange={event => onChange && onChange(event.target.value)}
                    disabled={!options}
                >
                    <MenuItem value="none">None</MenuItem>
                    {options.map((type) => (
                        <MenuItem key={type.id} value={type.id}>{type.name}</MenuItem>
                    ))}
                </Select>
            </FormControl>
        </>
    );
}
