import { Typography } from '@mui/material';
import { attributeTypes } from '../../resources/attr-types';



export function objectToString(object) {

  let label = object.id || "[object]";

  if (object?.type === "entity") {
    label = (object.article ? object.article + " " : "") + object.name;

  } else if (object?.type === "attribute") {
    // if (value.id === "text") {
    //   return "";
    // }
    label = "textual value";

    if (!!object?.id) {
      const type = attributeTypes.find(type => type.id === object.id);

      label = ":" + (type?.value || type?.name || object.id) + ":";
    }
  }
  return label;
}

export function StatementObject({ value }) {

  let label = "[object]";

  if (value?.type === "entity" && !!value.entity) {
    label = value.toString();

  } else if (value?.type === "attribute") {
    return (<></>);
//    label = value.toString();
    // // if (value.id === "text") {
    // //   return "";
    // // }
    // label = "textual value";

    // if (!!value.attribute?.id) {
    //   const type = attributeTypes.find(type => type.id === value.attribute.id);
    //   label = type?.value || type?.name || value.attribute.id;
    // }

    //return (<Typography component="span" sx={{ fontSize: 14, fontWeight: 400, letterSpacing: -1, fontFamily: 'monospace, sans-serif', textTransform: "uppercase", whiteSpace: "nowrap", }}>:{label}:</Typography>);

  } else if (value?.type === "constant") {
    // if (value.id === "text") {
    //   return "";
    // }
    label = "constant - todo";

    return (<Typography component="span" sx={{ fontSize: 14, fontWeight: 400, letterSpacing: -1, fontFamily: 'monospace, sans-serif', textTransform: "uppercase", whiteSpace: "nowrap", }}>:{label}:</Typography>);
  }

  return (<Typography component="span" sx={{ fontSize: 16, fontWeight: 500, fontFamily: 'Montserrat, sans-serif', }}>{label}</Typography>);
}
