import { Box, Typography } from "@mui/material";
import { blueGrey, green, lightBlue, teal } from "@mui/material/colors";
import { Panel, SectionHeader } from "./Layout";


export default function Examples({ children, ...props }) {

    return (
        <Panel {...props}>
            <SectionHeader> Example</SectionHeader>
            <Box sx={{ 
                fontSize: 15,
                p: t => t.spacing(0, 1, 0, 1),
                fontFamily: 'Montserrat, sans-serif',
            }} ml={1} mr={2}>{children}</Box>
        </Panel>
    )
}