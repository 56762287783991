import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, getFirestore, limit, query, Timestamp } from "firebase/firestore";
import { ClaimDocument, ObjectDocument } from "./claim";
import { claimConverter, claimsFromFirestore } from "./claimService";
import { EntityDocument } from "./entity";
import { entityConverter } from "./entityService";
import { removeEmptyValues } from "./utils";

const db = getFirestore();

function claimsToFirestore(typeClaims) {
    let claims = null;

    if (typeClaims && Array.isArray(typeClaims) && typeClaims.length > 0) {
        claims = [];

        for (const claim of typeClaims) {
            const { ref, modified, claims: subclaims, ...data } = claim;

            claims.push(removeEmptyValues({
                ...claimConverter.toFirestore(data),
                subject: null,
                claims: claimsToFirestore(subclaims),
                link: doc(db, ref.path),
                modified: modified ? Timestamp.fromDate(modified) : null,
            }));
        }
    }

    return claims;
}

function vcClaimsFromFirestore(subject, typeClaims) {
    let claims = [];

    if (typeClaims && Array.isArray(typeClaims) && typeClaims.length > 0) {

        for (const claim of typeClaims) {
            const { link, modified, object, claims: subclaims, ...data } = claim;

            

            claims.push(Object.assign(new ClaimDocument(), {
                ...claimsFromFirestore(link, { subject, object, ...data}),
                claims: vcClaimsFromFirestore(object, subclaims),
                ref: { id: link.id, path: link.path },
                modified: modified ? modified.toDate() : null,
            }));
        }
    }

    return claims;
}

// Firestore data converter
export const vcTypeConverter = {
    toFirestore: ({ ref, ...entity }) => {

        const {
            subject: { ref: subjectRef, verb, scope, ...subject },
            claims,
            ...data
        } = entity;

        return removeEmptyValues({
            subject: {
                ...entityConverter(EntityDocument).toFirestore(subject),
                link: doc(db, subjectRef.path),
            },
            claims: claimsToFirestore(claims),
            ...data
        });
    },
    fromFirestore: (snapshot, options) => {
        const {
            subject: subjectData,
            claims,
            modified,
            ...data
        } = snapshot.data(options);

        const { link: subjectRef, modified: subjectModified, ...subject } = subjectData;

        const ref = subjectRef
            ? { id: subjectRef.id, path: subjectRef.path }
            : null;

        const subjectDoc = Object.assign(new EntityDocument(), {
            ref,
            modified: subjectModified ? subjectModified.toDate() : null,
            ...subject
        })

        return Object.assign(new ClaimDocument(),
            {
                ref: { id: snapshot.id, path: snapshot.ref.path },
                subject: subjectDoc,
                claims: vcClaimsFromFirestore(subjectData, claims),
                modified: modified ? modified.toDate() : null,
                ...data,
            }
        );
    }
};

const collectionRef = collection(db, "vcType").withConverter(vcTypeConverter);

const allQuery = query(collectionRef, limit(100));

export async function getAllCredentialTypes() {
    return getDocs(allQuery).then(snap => snap.docs.map(doc => doc.data()));
}

export async function createCredentialType(vc) {
    return await addDoc(collectionRef, {
        ...vc,
        modified: Timestamp.now(),
    });
}

export async function getCredentialType(path) {
    return getDoc(doc(db, path).withConverter(vcTypeConverter));
}

export async function deleteCredentialType(id) {
    return await deleteDoc(doc(db, "vcType", id));
}
