import { Add, ArrowBack, Close, CopyAll, Delete, DeleteForever, Edit } from "@mui/icons-material";
import { Box, Button, Grid, Skeleton, Toolbar, Typography } from "@mui/material";
import { grey, yellow } from "@mui/material/colors";
import { CLAIM_DRAFT_KEY_PREFIX } from "component/claim/ClaimTypeDesigner";
import { CardView } from "component/element/CardView";
import { createDraft } from "component/element/Drafts";
import { PageEnd, PageSubtitle, PageTitle } from "component/element/Layout";
import ButtonLink from "component/element/Link";
import ProtectedButton from "component/element/ProtectedButton";
import { VocabTermTableView } from "component/element/TableView";
import { findSubjectClaims } from "document/claimService";
import { deleteEntity } from "document/entityService";
import { useApp } from "lib/app";
import moment from "moment";
import { useEffect, useState } from "react";
import { useLoaderData, useNavigate } from "react-router-dom";
import { ENTITY_DRAFT_KEY_PREFIX } from "./EntityTypeDesigner";

function Line({ mt, label, value }) {
    return (
        <Box mt={mt}>
            <Typography variant="body2" component="span" sx={{ pr: 1, fontWeight: 700, }}>
                {label}:
            </Typography>
            <Typography component="span" sx={{ fontSize: 16 }}>
                {value}
            </Typography>
        </Box>
    )
}

export function EntityOverview({ entity, verbs = true, ...params }) {
    return (<Box {...params}>
        <Typography variant="h5" gutterBottom>{entity?.toString()}</Typography>
        <Typography variant="body1">
            {entity.description}
        </Typography>
        <Box mt={2}>
            <Line label="Term" value={entity.term} />
            <Line label="Vocabulary" value={entity.vocab} />
            {verbs && entity.verb && entity.verb.length > 0 && (
                <Line label="Verbs" value={entity.verb.join(", ")} mt={2} />
            )}
        </Box>
        {!!entity.scope?.credentialSubject && (
            <Typography mt={2} sx={{ fontSize: 15, }}>
                A verifiable credential can be issued to this entity as a subject.
            </Typography>
        )}
    </Box>);
}

function EntityDeleteConfirmation({ entity, onCancel }) {

    const navigate = useNavigate();
    const { progress, notify } = useApp()

    const handleDelete = () => {
        progress(true);
        deleteEntity(entity.ref.id)
            .then(() => {
                notify("An entity has been deleted.", "info");
                navigate(-1);
            })
            .catch((e) => notify(e, "error"))
            .then(() => progress(false))
    }

    return (
        <Toolbar sx={{ m: 0, p: 0, width: "100%", }} disableGutters>
            <ProtectedButton
                startIcon={<DeleteForever />}
                onClick={handleDelete}
                disabled={!entity}
                color="error"
                variant="contained"
            >
                Delete the entity forever
            </ProtectedButton>
            <Box sx={{ flexGrow: 1, }}></Box>
            <Button
                startIcon={<Close />}
                onClick={onCancel}
            >
                Cancel
            </Button>
        </Toolbar>
    );
}

function EntityActions({ entity, onDelete }) {

    const navigate = useNavigate();

    const handleClone = () => {
        const cloneId = Date.now();
        const { ref, name = "", ...data } = entity;
        createDraft(ENTITY_DRAFT_KEY_PREFIX + cloneId, { ...data, name: (name + " clone").trim() }, 0, 5);
        navigate(`/entity/draft/` + cloneId);
    };

    return (
        <Toolbar sx={{ m: 0, p: 0, width: "100%" }} disableGutters>
            <ButtonLink color="secondary" startIcon={<ArrowBack />} to="/entity/">Directory</ButtonLink>
            <Button
                startIcon={<CopyAll />}
                onClick={handleClone}
                disabled={!entity}
            >
                Clone &amp; Design
            </Button>
            <ProtectedButton
                startIcon={<Edit />}
                disabled={!entity || true}
            >
                Edit [TODO]
            </ProtectedButton>

            <Box sx={{ flexGrow: 1, }}></Box>

            <ProtectedButton
                startIcon={<Delete />}
                onClick={onDelete}
                disabled={!entity}
                color="error"
            >
                Delete
            </ProtectedButton>
        </Toolbar>
    );
}

function EntityAttributes({ entity, claims = [] }) {

    const navigate = useNavigate();

    const handleNewAttr = () => {
        const cloneId = Date.now();
        createDraft(CLAIM_DRAFT_KEY_PREFIX + cloneId, { subject: entity, object: { type: "attribute" } }, 1, 1);
        navigate(`/claim/draft/` + cloneId);
    }

    const attributes = claims?.filter(claim => claim.object.type === "attribute");

    return (<>
        <SectionLabel>Attributes</SectionLabel>
        <Box sx={{ mr: 2, ml: 1 }}>
            {attributes?.length > 0 && attributes.map((attribute) => (
                <Box mb={1} key={attribute.ref.path}>
                    <CardView
                        title={`${attribute.verb} ${attribute.name}`}
                        subtitle={attribute.object.toString()}
                        description={attribute.description}
                        uri={attribute.uri()}
                        onClick={() => navigate(`/claim/${attribute.ref.id}`)}
                    />
                </Box>
            ))}
            {!attributes && (
                <Box mb={1} >
                    <Skeleton animation="wave" sx={{ height: 120, }} variant="rectangular" />
                </Box>
            )}
            {attributes?.length === 0 && (
                <Box mb={1} mt={2}>
                    <Typography>No attributes</Typography>
                </Box>
            )}
        </Box>
        <Button startIcon={<Add />} onClick={handleNewAttr}>New Attribute</Button>
    </>);
}

function EntityRelations({ entity, claims = [] }) {

    const navigate = useNavigate();

    const handleNewRel = () => {
        const cloneId = Date.now();
        createDraft(CLAIM_DRAFT_KEY_PREFIX + cloneId, { subject: entity, object: { type: "entity" } }, 1, 1);
        navigate(`/claim/draft/` + cloneId);
    }

    const entities = claims?.filter(claim => claim.object.type === "entity");

    return (<>
        <SectionLabel>Relations</SectionLabel>
        <Box sx={{ mr: 2, ml: 1 }}>
            {entities?.length > 0 && entities.map((entity) => (
                <Box mb={1} key={entity.ref.path}>
                    <CardView
                        title={entity.toString()}
                        description={entity.description}
                        uri={entity.uri()}
                        onClick={() => navigate(`/claim/${entity.ref.id}`)}
                    />
                </Box>
            ))}
            {!entities && (
                <Box mb={1} >
                    <Skeleton animation="wave" sx={{ height: 120,  }} variant="rectangular" />
                </Box>
            )}
            {entities?.length === 0 && (
                <Box mb={1} mt={2}>
                    <Typography>No relations</Typography>
                </Box>
            )}
        </Box>
        <Button startIcon={<Add />} onClick={handleNewRel}>New Relation</Button>
    </>);
}

function SectionLabel({ children }) {
    return (
        <Typography
            gutterBottom
            sx={{
                fontSize: 15,
                fontWeight: 500,
                textTransform: "uppercase",
                fontFamily: 'Montserrat, sans-serif',
                color: "text.secondary",
                mb: 1,
            }}>
            {children}
        </Typography>
    );
}

export function EntityView({ }) {

    const [mode, setMode] = useState(null);

    const entity = useLoaderData();

    const { progress } = useApp();
    const [claims, setClaims] = useState(null);

    useEffect(() => {
        const fetchClaims = async (path) => {
            try {
                progress(true);
                const docs = await findSubjectClaims(path);
                setClaims(docs);
                progress(false);

            } catch (e) {
                console.log(e);
            }
        }
        fetchClaims(entity.ref.path);
    }, []);

    const handleNewCred = () => {

    }

    return (
        <Box>
            <Box>
                <PageTitle>Entity: {entity?.toString()}</PageTitle>
                <PageSubtitle>
                    {entity.description}
                </PageSubtitle>
            </Box>
            <VocabTermTableView mt={3} term={entity.term} vocab={entity.vocab} />
            <Box
                color={grey[50]}
                borderRadius={1}
                mr={2}
                mt={2}
                mb={2}
                sx={{
                    borderStyle: "solid",
                    borderColor: yellow[200],
                    borderWidth: 0,
                }}
            >
                {!mode && (<EntityActions entity={entity} onDelete={() => setMode("delete")} />)}
                {mode === "delete" && (<EntityDeleteConfirmation entity={entity} onCancel={() => setMode(null)} />)}
            </Box>
            <Box mt={4}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={6}>
                        <EntityAttributes entity={entity} claims={claims} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} mt={{ xs: 2, sm: 2, md: 0 }}>
                        <EntityRelations entity={entity} claims={claims} />
                    </Grid>
                </Grid>
            </Box>
            <Box mt={3}>
                <SectionLabel>Usage</SectionLabel>
                <Box sx={{ mr: 2, ml: 1, mb: 1 }}>
                    <Typography>a list of credential types [TODO]</Typography>
                    <Button startIcon={<Add />} onClick={handleNewCred} disabled>New Credential Type [TODO]</Button>
                </Box>
            </Box>
            {entity.modified && (
                <Box mt={3}>
                    <Typography color="text.secondary" sx={{ fontSize: 12, }}>
                        Last modified: {moment(entity.modified).format('lll')}
                    </Typography>
                </Box>
            )}
            <PageEnd />
        </Box>
    );
}