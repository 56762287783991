import Designer from 'component/element/Designer';
import { deleteDraft } from 'component/element/Drafts';
import { StepDescription } from 'component/step/StepDescription';
import { StepEnglishName } from 'component/step/StepEnglishName';
import { CamelIdSchema, StepId } from 'component/step/StepId';
import { StepVerb } from 'component/step/StepVerb';
import { designConverter } from 'document/design';
import { EntityDocument, entityJsonConverter } from 'document/entity';
import { createEntity } from 'document/entityService';
import { useApp } from "lib/app";
import { useNavigate, useParams } from 'react-router-dom';
import EntityTypeStart from './EntityTypeStart';
import { StepEntityReview } from './step/StepEntityReview';
import { StepEntityScope } from './step/StepEntityScope';

const steps = [
  {
    id: "name",
    key: ["name", "article"],
    control: StepEnglishName,
    label: "Name",
    description: "Define the entity name.",
  },
  {
    id: "term",
    key: ["term", "vocab"],
    control: StepId,
    schema: CamelIdSchema,
    param: {
      syntax: "camel",
    },
    label: "Identifier",
  },
  {
    id: "verb",
    control: StepVerb,
    label: "Verbs",
    description:
      `Define verbs that could be used together with the entity in subject-verb-object sentence where the entity represents the subject.`,
  },
  {
    id: "scope",
    key: ["scope", "access"],
    control: StepEntityScope,
    label: "Scope",
  },
  {
    id: "description",
    control: StepDescription,
    label: "Description",
    description:
      `Please describe the claim type.`,
  },
];

export const ENTITY_DRAFT_KEY_PREFIX = "entity-draft-";

export const ENTITY_DRAFT_DEF = {
  prefix: ENTITY_DRAFT_KEY_PREFIX,
  toUrl: (id) => `/entity/draft/${id}`,
  label: (draft) => `${draft.data.article || ""} ${draft.data.name?.toLowerCase() || "unnamed"} entity`.trim(),
  converter: designConverter(entityJsonConverter),
}

export default function EntityTypeDesigner() {

  const { draftId } = useParams();

  const { progress, notify } = useApp()

  const navigage = useNavigate();

  const handleComplete = (data) => {
    progress(true);
    createEntity(data)
      .then((ref) => {
        notify("A new entity has been created.");
        deleteDraft(ENTITY_DRAFT_KEY_PREFIX + draftId);
        navigage(`/entity/${ref.id}`);
      })
      .catch((e) => notify(e, "error"))
      .then(() => progress(false))
  }

  return (
    <Designer
      title={draftId ? "New Entity" : "New Entity"}
      subtitle="An entity represents an identifiable structured object"

      draftId={draftId ? ENTITY_DRAFT_KEY_PREFIX + draftId : null}
      draftDef={ENTITY_DRAFT_DEF}

      steps={steps}
      simple={true}

      defaultData={new EntityDocument()}

      DocumentType={EntityDocument}

      StartComponent={EntityTypeStart}
      EndComponent={StepEntityReview}

      onComplete={handleComplete}
    />
  );
}
