

export class EntityDocument {

    static uri(entity) {
        return entity.vocab + entity.term;
    }

    static asString(entity) {
        return ((entity.article ? entity.article + " " : "") + (entity.name || entity.term)).toLowerCase();
    }

    uri() {
        return EntityDocument.uri(this);
    }

    toString() {
        return EntityDocument.asString(this);
    }
}

export const entityJsonConverter = {
    fromJson: (json) => {

        const { modified, ...rest} = json;

        return Object.assign(new EntityDocument(), {
            ...rest,
            modified: modified ? new Date(modified) : null,
        });
    },
}


