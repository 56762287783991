import { Approval, CreateOutlined, Search, Source, Verified } from "@mui/icons-material";
import { Masonry } from "@mui/lab";
import { alpha, Avatar, Box, Card, CardActionArea, CardActions, CardContent, Stack, Typography } from "@mui/material";
import { amber, cyan, green, grey, lightBlue, lime, teal, yellow } from "@mui/material/colors";
import { CLAIM_DRAFT_DEF } from "component/claim/ClaimTypeDesigner";
import { DraftCardActions, useDrafts } from "component/element/Drafts";
import { ENTITY_DRAFT_DEF } from "component/entity/EntityTypeDesigner";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { VC_DRAFT_DEF } from "./credential/CredentialTypeDesigner";


const actions = {
    Design: [
        {
            title: "Design Credential",
            description: "Design a new verifiable credential type",
            url: "/credential-type/designer",
            bgcolor: teal[300],
            icon: props => <CreateOutlined {...props} />,
        },
        {
            title: "Design Claim",
            description: "Design a new claim type",
            url: "/claim/designer",
            color: grey[50],
            bgcolor: teal[500],
            icon: props => <CreateOutlined {...props} />,
        },
        {
            title: "Design Entity",
            description: "Design a new entity",
            url: "/entity/designer",
            color: grey[50],
            bgcolor: teal[700],
            icon: props => <CreateOutlined {...props} />,
        },
    ],
    "Issue & Verify": [
        {
            title: "Issue Credential",
            description: "Issue a new verifiable credential to a subject",
            url: "/issuer",
            bgcolor: yellow[400],
            icon: props => <Approval {...props} />,
        },
        {
            title: "Verify Credential",
            description: "Verify an existing verifiable credential genuinity",
            url: "/verifier",
            color: grey[50],
            bgcolor: green[400],
            icon: props => <Verified {...props} />,
        }
    ],
    "Explore & Manage": [
        // {
        //     title: "Credentials",
        //     description: "Manage issued verfiable credentials",
        //     bgcolor: lightBlue[200],
        //     icon: props => <Search {...props} />,
        // },
        {
            title: "Credential Types",
            description: "Manage verifiable credential types",
            color: grey[50],
            bgcolor: lightBlue[400],
            url: "/credential-type/",
            icon: props => <Search {...props} />,
        },
        {
            title: "Claim Types",
            description: "Manage claim types",
            color: grey[50],
            bgcolor: lightBlue[600],
            url: "/claim/",
            icon: props => <Search {...props} />,
        },
        {
            title: "Entities",
            description: "Manage entities",
            color: grey[50],
            bgcolor: lightBlue[800],
            url: "/entity/",
            icon: props => <Search {...props} />,
        },
    ],
}

export function ActionPanel({ id, label, content, navigate }) {

    return (
        <Box mt={1} mb={3}>
            <Typography
                gutterBottom
                sx={{
                    fontSize: 15,
                    fontWeight: 500,
                    textTransform: "uppercase",
                    fontFamily: 'Montserrat, sans-serif',
                    color: "text.secondary",
                }}
            >
                {label}
            </Typography>
            <Masonry columns={{ xs: 1, sm: 1, md: 2, lg: 3 }} spacing={1} sx={{ ml: 1, }}>
                {content.map((action, index) => {

                    const Content = action.Content;

                    return (
                        <Card variant="outlined"
                            key={id || index}
                            elevation={0}
                            sx={{

                                borderStyle: "none",
                                // color: action.color || "inherit",
                            }}
                        >
                            <Stack direction="row" justifyItems="center">
                                {action.icon && (
                                    <Avatar variant="rounded"
                                        sx={{
                                            color: action.color || "inherit",
                                            bgcolor: action.bgcolor || "inherit",
                                            // bgcolor: action.bgcolor && alpha(action.bgcolor, .5) || "inherit",
                                            height: "auto",
                                            borderRadius: 0,
                                        }}
                                    >
                                        {action.icon({ sx: { fontSize: 24, } })}
                                    </Avatar>
                                )}
                                <Box sx={{
                                    width: "100%",
                                    // bgcolor: action.bgcolor || "inherit",
                                    // bgcolor: action.bgcolor && alpha(action.bgcolor, .55) || "inherit",
                                    borderColor: alpha(action.bgcolor, .4) || "inherit",
                                    borderWidth: 1,
                                    borderStyle: "solid",
                                    borderLeft: "none",
                                }}>
                                    <CardActionArea disabled={!action.url} onClick={() => navigate(action.url)}>
                                        <CardContent >
                                            <Typography gutterBottom component="div"
                                                sx={{
                                                    fontSize: 20,
                                                    fontFamily: 'Montserrat, sans-serif',
                                                    fontWeight: 500,
                                                    mt: .5,
                                                }}
                                            >
                                                {action.title}
                                            </Typography>
                                            {action.description && (
                                                <Typography
                                                    sx={{
                                                        fontSize: 16,
                                                    }}
                                                    color="text.secondary"

                                                >
                                                    {action.description}
                                                </Typography>
                                            )}
                                            {Content && (<Content action={action} />)}
                                        </CardContent>

                                    </CardActionArea>
                                    {action.actions && (
                                        <CardActions sx={{ pt: .5, mt: 0, mb: 0, pb: .5, ml: 1, mr: 1 }}>
                                            {action.actions}
                                        </CardActions>
                                    )}
                                </Box>
                            </Stack>

                        </Card>

                    );
                })}
            </Masonry>
        </Box>
    );
}

export default function Dashboard() {

    const { drafts, deleteDraft } = useDrafts(
        [
            VC_DRAFT_DEF,
            CLAIM_DRAFT_DEF,
            ENTITY_DRAFT_DEF,

        ]
    );

    const navigate = useNavigate();

    return (
        <Box mt={{ xs: 3, sm: 4, md: 5, lg: 6 }}>
            {(drafts?.length > 0) && (
                <ActionPanel label="Complete" content={drafts
                    .sort((a, b) => b.modified.getTime() - a.modified.getTime())
                    .map(draft => ({
                        title: `Draft: ${draft.label}`,
                        Content: () => (
                            <Box p={0} m={0}>
                                <Box sx={{ fontSize: 15, mt: 1.5 }} >{`Modified  ${moment(draft.modified).startOf().fromNow()}`}</Box>
                                <Box sx={{ fontSize: 14, mt: 1, color: "text.secondary" }}>{`Created  ${moment(draft.created).format("ddd MMM D, h:MM a")}`}</Box>
                            </Box>),
                        icon: props => <Source {...props} />,
                        color: grey[50],
                        bgcolor: amber[300],
                        url: draft.url,
                        id: draft.id,
                        actions: (<DraftCardActions onDelete={deleteDraft} id={draft.id} key={draft.id} />),
                    }))} navigate={navigate} />
            )}
            {Object.entries(actions).map(([key, value]) => (
                <ActionPanel key={key} label={key} content={value} navigate={navigate} />
            ))}
        </Box>
    );
}