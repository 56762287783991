import { Add, Delete, DeleteOutline, DeleteOutlined, Remove, RemoveCircleOutline, RemoveCircleOutlined, RemoveOutlined } from '@mui/icons-material';
import { Button, Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, Grid, IconButton, TextField } from '@mui/material';
import { grey } from '@mui/material/colors';
import { Box } from '@mui/system';
import { FormPanel } from 'component/element/Layout';
import { useState } from 'react';
import { credentialDomains } from 'resources/credential-domains';
import * as yup from 'yup';

StepDomain.Schema = yup.array(
  yup.string()
    .trim()
    .required()
    .min(4)
    .matches(/^[a-zA-Z][a-zA-Z\s]+$/, 'must start with a letter followed by letters or spaces')

).typeError("Please select at least one domain").min(1)
  .test({
    name: 'one-true',
    message: 'Please select at least one domain',
    test: val => val.some(v => v),
  });

export function StepDomain({
  defaultValue = [],
  error = false,
  onChange = () => { },
  onRemove = () => { },
}) {

  const [custom, setCustom] = useState(getDefaultCustomDomains(defaultValue));
  const [domains, setDomains] = useState(credentialDomains.map(domain => defaultValue.includes(domain.id)));

  const handleAddNew = () => {
    setCustom(c => [...c, Math.random()]);
  }

  const handleDelete = (index, offset) => () => {
    setCustom(c => c.filter((_, i) => i !== index));
    onRemove(index + offset);
  }

  const handleDomainChange = (index) => () => {
    if (domains[index]) {
      onRemove(index);
    }
    setDomains(domains => domains.map((domain, di) => (di === index) ? !domain : domain));
  }

  const checked = domains.filter(d => d).length;

  return (
    <FormPanel>
      <FormControl error={!!error}>
        <FormGroup sx={{ mb: 1 }}>
          <Grid container spacing={{ xs: 1, sm: 2, }} alignItems="center">
            {credentialDomains
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((domain, index) => (
                <Grid item xs={12} sm={6} md={4} key={domain.id}>
                  <FormControlLabel
                    control={<Checkbox
                      name="domain[]"
                      checked={domains[index]}
                      value={domain.id}
                      onChange={handleDomainChange(index)}
                    />}
                    label={domain.name}
                  />
                </Grid>
              ))}
          </Grid>
        </FormGroup>

        {custom.length > 0 && (
          <Box pl={1} pr={2}>
            {custom.map((domain, index) => {

              const e = Array.isArray(error) && error[checked + index];

              return (<Box mt={2} key={domain}>
                <Box display="flex" alignItems="center">
                  <TextField
                    variant="standard"
                    fullWidth
                    // size="small"
                    defaultValue={typeof domain === "string" ? domain : ""}
                    placeholder='a domain name'
                    name="domain[]"
                    error={!!e}
                    sx={{
                      mr: 1,
                    }}
                  />
                  <IconButton
                    size="small"
                    sx={{
                      color: grey[500],
                    }}
                    onClick={handleDelete(index, checked)}
                  ><DeleteOutline /></IconButton>
                </Box>
                <Box>
                  {!!e && (<FormHelperText sx={{ ml: 0, p: t => t.spacing(0, 0, 0, 0) }}>{e}</FormHelperText>)}
                </Box>
              </Box>
              );
            })}
          </Box>
        )}
        <Box mt={2} mb={0}>
          <Button
            startIcon={<Add />}
            size="small"
            color="secondary"
            onClick={handleAddNew}
          >
            Add</Button>
        </Box>
        {!!error && (typeof error === "string") && (<FormHelperText sx={{ p: t => t.spacing(1, 0, 0, 0) }}>{error}</FormHelperText>)}
      </FormControl>
    </FormPanel >
  );
}

function getDefaultCustomDomains(defaultValues) {

  const defined = credentialDomains.map(d => d.id);

  return defaultValues.filter(v => !defined.includes(v));
}