import { Box, Step, StepConnector, stepConnectorClasses, StepLabel, Stepper, styled } from "@mui/material";
import { blueGrey, grey, lightBlue, lime, teal } from "@mui/material/colors";

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.label}`]: {
        top: 22,
    },
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`&.${stepConnectorClasses.label}`]: {
            top: 22,
            fontFamily: 'Montserrat, sans-serif',
            letterSpacing: 4,
        },
    
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                `linear-gradient( 95deg,${teal[500]} 0%,${lightBlue[500]} 50%,${lightBlue[800]} 100%)`,
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                `linear-gradient( 95deg,${teal[500]} 0%,${teal[300]} 50%,${teal[500]} 100%)`,
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 4,
        border: 0,
        backgroundColor:
            theme.palette.mode === 'dark' ? grey[800] : grey[300],
        borderRadius: 1,
    },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 48,
    height: 48,
    fontSize: 20,
    fontWeight: 700,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'Montserrat, sans-serif',
    ...(ownerState.active && {
        backgroundImage:
            `linear-gradient( 136deg, ${lightBlue[300]} 0%, ${lightBlue[600]} 50%, ${lightBlue[800]} 100%)`,
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
        backgroundImage:
            `linear-gradient( 136deg, ${teal[200]} 0%, ${teal[500]} 50%, ${teal[700]} 100%)`,
    }),
}));

function ColorlibStepIcon({ active, completed, className, icon }) {
    return (
        <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
            {String(icon)}
        </ColorlibStepIconRoot>
    );
}


export default function ColoredStepper({ steps, active, completed, ...props }) {
    return (
        <Box {...props}>
            <Stepper activeStep={active} orientation="horizontal" alternativeLabel connector={<ColorlibConnector />}
                sx={{
                }}
            >
                {steps.map((step, index) => (
                    <Step key={step.label} completed={index !== active && index < completed}>
                        <StepLabel
                            StepIconComponent={ColorlibStepIcon}
                            sx={{
                                fontFamily: 'Montserrat, sans-serif',
                                color: blueGrey[800],
                            }}
                        >
                            {step.label}
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>
        </Box>
    );
}