import { getAttributeName } from "resources/attr-types";
import { entityJsonConverter } from "./entity";

export class ClaimDocument {
    uri() {
        return this.vocab + this.term;
    }

    toString() {
        return (this.verb ? this.verb + " " : "") + (this.name || (this.object.type === "entity" && this.object.entity.toString()) || this.term);
    }
}

export class AttributeDocument {


}

export class ObjectDocument {
    static asString(entity) {

        if (!entity) {
            return null;
        }
        if (entity.type === "attribute") {
            return getAttributeName(entity.attribute?.id);    //TODO replace with proper name
        }
        if (entity.type === "entity") {
            const { article, name, term } = entity.entity;
            return ((article ? article + " " : "") + (name || term)).toLowerCase();
        }
        return entity.toString();

    }

    isAttribute() {
        return this.type === "attribute";
    }

    isRel() {
        return this.type === "entity";
    }

    toString() {
        return ObjectDocument.asString(this);
    }
}

export const claimJsonConverter = {
    fromJson: ({ subject, object, ...rest }) => {
        
        let documentObject = object;
        if (object?.type === "entity") {
            const { entity = null } = object;
            documentObject.entity = entity ? entityJsonConverter.fromJson(entity) : null;
        }

        return Object.assign(new ClaimDocument(), {
            subject: subject ? entityJsonConverter.fromJson(subject) : null,
            object: object ? Object.assign(new ObjectDocument(), object) : null,
            ...rest,
        })
    },
}