import { Box, Button } from "@mui/material";
import { grey } from "@mui/material/colors";
import { PageEnd, PageSubtitle, PageTitle } from "../element/Layout";


export default function Verify({ }) {

    return (
        <Box>
            <Box direction="row" alignItems="baseline" mr={1} display="flex">
                <Box mr={2} sx={{ flexGrow: 0 }}>
                    <PageTitle>Verify Credential</PageTitle>
                    <PageSubtitle>
                    Verify an existing verifiable credential genuinity
                    </PageSubtitle>
                </Box>
            </Box>

            <Box mt={3}>
                <Box p={t => t.spacing(4, 8, 4, 8)} sx={{
                    bgcolor: grey[200],
                }}>
                    TODO Drag & Drop
                </Box>
                <Button 
                    variant="outlined"
                    color="secondary"
                sx={{
                    mt: 1,
                    p: t => t.spacing(4, 8, 4, 8)
                }}>TODO Upload</Button>
            </Box>
            <PageEnd />
        </Box>
    );
}