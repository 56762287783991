import { App, Main } from "App";
import ClaimDirectory from "component/claim/ClaimDirectory";
import ClaimTypeDesigner, { CLAIM_DRAFT_DEF } from "component/claim/ClaimTypeDesigner";
import { ClaimView } from "component/claim/ClaimView";
import CredentialDirectory from "component/credential/CredentialDirectory";
import CredentialTypeDesigner, { VC_DRAFT_DEF } from "component/credential/CredentialTypeDesigner";
import { CredentialTypeView } from "component/credential/CredentialTypeView";
import Dashboard from "component/Dashboard";
import { getDraft } from "component/element/Drafts";
import EntityDirectory from "component/entity/EntityDirectory";
import EntityTypeDesigner, { ENTITY_DRAFT_DEF } from "component/entity/EntityTypeDesigner";
import { EntityView } from "component/entity/EntityView";
import IssuerDirectory from "component/issuer/IssuerDirectory";
import Verify from "component/verifier/Verify";
import { getClaim } from "document/claimService";
import { getEntity } from "document/entityService";
import { getCredentialType } from "document/vcTypeService";
import {
  createBrowserRouter,
  redirect,
  RouterProvider
} from "react-router-dom";

// import Root, { rootLoader } from "./routes/root";
// import Team, { teamLoader } from "./routes/team";

const router = createBrowserRouter([
  {
    path: "/",
    element: (<Main />),
    children: [
      {
        index: true,
        element: (<Dashboard />),
      },
      {
        path: "issuer/",
        element: (<IssuerDirectory />),
      },
      {
        path: "verifier/",
        element: (<Verify />),
      },
      {
        path: "credential-type/",
        element: (<CredentialDirectory />),
      },
      {
        path: "credential-type/:typeId",
        element: (<CredentialTypeView />),
        loader: async ({ params }) => {
          const e = await getCredentialType(`vcType/${params.typeId}`);

          if (e.exists()) {
            return e.data();
          }
          throw new Response("Not Found", { status: 404 });
        },
      },
      {
        path: "credential-type/designer",
        element: (<CredentialTypeDesigner />),
      },
      {
        path: "credential-type/draft/:draftId",
        element: (<CredentialTypeDesigner />),
        loader: async ({ params }) => {
          const e = await getDraft(VC_DRAFT_DEF, params.draftId);
          if (!e) {
            return redirect("/credential-type/designer");
          }
          return e;
        },
      },
      {
        path: "claim/",
        element: (<ClaimDirectory />),
      },
      {
        path: "claim/:entityId",
        element: (<ClaimView />),
        loader: async ({ params }) => {
          const e = await getClaim(`claim/${params.entityId}`);
          if (e.exists()) {
            return e.data();
          }
          throw new Response("Not Found", { status: 404 });
        },
      },
      {
        path: "claim/designer",
        element: (<ClaimTypeDesigner />),
      },
      {
        path: "claim/draft/:draftId",
        element: (<ClaimTypeDesigner />),
        loader: async ({ params }) => {
          const e = await getDraft(CLAIM_DRAFT_DEF, params.draftId);
          if (!e) {
            return redirect("/claim/designer");
          }
          return e;
        },
      },
      {
        path: "entity/",
        element: (<EntityDirectory />),
      },
      {
        path: "entity/:entityId",
        element: (<EntityView />),
        loader: async ({ params }) => {
          const e = await getEntity(`entity/${params.entityId}`);
          if (e.exists()) {
            return e.data();
          }
          throw new Response("Not Found", { status: 404 });
        },
      },
      {
        path: "entity/designer",
        element: (<EntityTypeDesigner />),
      },
      {
        path: "entity/draft/:draftId",
        element: (<EntityTypeDesigner />),
        loader: async ({ params }) => {
          const e = await getDraft(ENTITY_DRAFT_DEF, params.draftId);
          if (!e) {
            return redirect("/entity/designer");
          }
          return e;
        },
      },
    ]
  },
]);

export default function Router({ children }) {

  return (
    <App>
      <RouterProvider router={router}>
        {children}
      </RouterProvider>
      </App>
  );
}
