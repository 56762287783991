import { Add } from "@mui/icons-material";
import { Masonry } from "@mui/lab";
import { alpha, Box, Card, CardActionArea, CardContent, Skeleton, Typography } from "@mui/material";
import { blueGrey } from "@mui/material/colors";
import { CardView } from "component/element/CardView";
import { DirectoryFilter } from "component/element/DirectoryFilter";
import { getAllCredentialTypes } from "document/vcTypeService";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useApp } from "../../lib/app";
import { PageEnd, PageSubtitle, PageTitle } from "../element/Layout";
import ButtonLink from "../element/Link";


function ClaimCard({ entity }) {

    const navigate = useNavigate();

    return (
        <Card elevation={0} sx={{
            bgcolor: alpha(blueGrey[100], 0.5),
        }}>
            <CardActionArea onClick={() => navigate(entity.ref.id)}>
                <CardContent>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        {entity.uri()}
                    </Typography>
                    <Typography variant="h5" component="div">
                        {entity.toString()}
                    </Typography>
                    {/* <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    adjective
                </Typography> */}
                    {entity.description && (
                        <Typography variant="body2" mt={1}>
                            {entity.description}
                        </Typography>
                    )}
                </CardContent>
            </CardActionArea>
        </Card>
    );
}


export default function CredentialDirectory({ }) {

    const [docs, setDocs] = useState()
    const [sortBy, setSortBy] = useState("NAME");
    const { progress, notify } = useApp();
    const naviage = useNavigate();

    useEffect(() => {
        const dataFetch = async () => {
            const docs = await getAllCredentialTypes();
            setDocs(docs);
        };

        progress(true);

        dataFetch()
            .catch((e) => notify(e, "error"))
            .then(() => progress(false))

    }, []);

    const list = docs || Array(2).fill(null);

    return (
        <Box>
            <Box direction="row" alignItems="baseline" mr={1} display="flex">
                <Box mr={2} sx={{ flexGrow: 0 }}>
                    <PageTitle>Credentials</PageTitle>
                    <PageSubtitle>
                        A credential type is a template used to issue and to present a verifiable credential
                    </PageSubtitle>
                </Box>
                <Box sx={{ flexGrow: 1 }} />
                <ButtonLink href="/credential-type/designer" startIcon={<Add />}>new type</ButtonLink>
            </Box>
            <Box mt={4} mr={1}>
                <DirectoryFilter sortBy={sortBy} onSortChange={setSortBy} />
            </Box>

            <Box mt={3}>
                {list?.length > 0 && (
                    <Masonry columns={{ xs: 1, sm: 2, md: 3 }}>
                        {list
                            .sort((a, b) => {
                                if (sortBy === "TERM") {
                                    return a.term.localeCompare(b.term);
                                }
                                return (a?.name || "").localeCompare(b?.name || "");
                            })
                            .map((vc, index) => !!vc
                                ? (<CardView key={vc.ref.path} 
                                    title={vc.toString()}
                                    subtitle={vc.subject.toString()}
                                    description={vc.description}
                                    uri={vc.uri()}
                                    onClick={() => naviage(`/credential-type/${vc.ref.id}`)}
                                />)
                                : (<Skeleton animation="wave" height={200} key={index} />)
                            )}
                    </Masonry>
                )}
                {list?.length === 0 && (
                    <Box>No credential types have been found</Box>
                )}
            </Box>
            <PageEnd />
        </Box>
    );
}