import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { FormPanel } from "component/element/Layout";
import { EntityOverview } from 'component/entity/EntityView';
import { AttributeSelector } from 'component/selector/AttributeSelector';
import { EntitySelector } from 'component/selector/EntitySelector';
import { ObjectDocument } from 'document/claim';
import { useState } from 'react';
import * as yup from 'yup';

StepObject.Type = ObjectDocument;

StepObject.Schema = yup.object({
  type: yup.string().trim().required().min(2),

  entity: yup.object().when("type", {
    is: "attribute",
    then: yup.object({
      id: yup.string().trim().required().min(2),
      //TODO validate optional fields, e.g. constraints
    }).required(),
  }),


  entity: yup.object().when("type", {
    is: "entity",
    then: yup.object({
      term: yup.string().trim().required().min(2),
      vocab: yup.string().trim().required().url(),
    }).required(),
  }),

}).required();

export function StepObject({
  defaultValue,
  error = false,
  onChange = () => { },
  onSubmit = () => { }
}) {

  const [value, setValue] = useState(defaultValue || { type: "attribute" });

  const handleMetaTypeChange = (event) => {
    setValue(_v => ({ type: event.target.value }));
    onChange && onChange({ ...value, type: event.target.value });
  };

  const handleChange = (_update) => {
    setValue(_v => ({ ..._v, [_v.type]: _update }));
    onChange && onChange(Object.assign(new ObjectDocument(), { ...value, [value.type]: _update }));
  }

  return (
    <>
      <FormPanel>
        <FormControl>
          <RadioGroup
            row
            value={value.type}
            onChange={handleMetaTypeChange}
            name="type"
          >
            {objectMetaTypes.map((metaType) => (
              <FormControlLabel
                key={metaType.id}
                value={metaType.id}
                label={metaType.label}
                control={<Radio />} />
            ))}
          </RadioGroup>
        </FormControl>
      </FormPanel>

      <FormPanel mt={2}>
        {value.type === "entity" && (<EntitySelector defaultValue={value.entity} error={error} onChange={handleChange} />)}
        {value.type === "attribute" && (<AttributeSelector defaultValue={value.attribute} error={error} onChange={handleChange} />)}
        {/* {value.type === "constant" && (<Constant value={value.constant} onChange={handleChange} error={error} />)} */}
      </FormPanel>
    </>
  );
}

export function StepObjectDetail({ value }) {

  if (!value || !value.entity) {
    return "Select an object to display details"
  }

  if (value.type !== "entity") {
    return (<>
    </>);
  }

  return (<>
    <EntityOverview entity={value.entity} />
  </>);
}

const objectMetaTypes = [
  {
    id: "entity",
    label: "Entity"
  },
  {
    id: "attribute",
    label: "Attribute"
  },
  // {
  //   id: "constant",
  //   label: "Constant"
  // },
]
