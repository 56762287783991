import { Button, TextField, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { lightBlue } from "@mui/material/colors";
import { alpha, Box } from "@mui/system";
import VocabSelector from "component/selector/VocabSelector";

export function DirectoryFilter({ sortBy, onSortChange = () => { } }) {

    return (
        <Box display="flex" justifyContent="space-between" alignItems="end">
            <Box
                flexGrow={1}
                mr={4}
                p={2}
                borderRadius={2}

                sxx={{
                    bgcolor: alpha(lightBlue[50], .25),
                    borderColor: lightBlue[100],
                    borderWidth: 1,
                    borderStyle: "solid",
                }}
            >
                <VocabSelector />
                <Box display="flex" mt={2} >
                    <Box flexGrow={1} mr={2}>
                        <TextField
                            placeholder="Filter by Name [TODO]"
                            variant="standard"
                            label="name"
                            fullWidth
                        />
                    </Box>
                    <Box flexGrow={0} alignSelf="self-end">
                        <Button
                            disableElevation
                            variant="contained"
                            sx={{ pl: 4, pr: 4 }}
                        >Search</Button>
                    </Box>
                </Box>
            </Box>
            <Box flexGrow={0} mb={2}>
                <Box component="span" sx={{ mr: 1, fontSize: 10, color: "text.secondary", textTransform: "uppercase" }}>
                    Sort by
                </Box>
                <ToggleButtonGroup
                    color="secondary"
                    exclusive
                    aria-label="Platform"
                    size="small"
                    value={sortBy}
                    onChange={(event) => onSortChange(event.target.value)}
                >
                    <ToggleButton disabled value="USAGE">Usage</ToggleButton>
                    <ToggleButton value="NAME">Name</ToggleButton>
                    <ToggleButton value="TERM">Term</ToggleButton>
                </ToggleButtonGroup>
            </Box>
        </Box>
    );
}