
export class DesignDocument {

}

export const designConverter = (documentConverter) => ({
    fromJson: ({ data, ...rest }) => {
        return Object.assign(new DesignDocument(), {
            data: documentConverter.fromJson(data),
            ...rest,
        })
    },
});