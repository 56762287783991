import { Approval, ArrowBack, Close, CopyAll, Delete, DeleteForever, Edit, PlayArrow } from "@mui/icons-material";
import { Box, Button, Divider, Toolbar, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { CardView, CardViewStub } from "component/element/CardView";
import { createDraft } from "component/element/Drafts";
import { PageEnd, PageSubtitle, PageTitle } from "component/element/Layout";
import ButtonLink from "component/element/Link";
import ProtectedButton from "component/element/ProtectedButton";
import { VocabTermTableView } from "component/element/TableView";
import { getEntity } from "document/entityService";
import { deleteCredentialType } from "document/vcTypeService";
import { useApp } from "lib/app";
import moment from "moment";
import { useEffect, useState } from "react";
import { useLoaderData, useNavigate } from "react-router-dom";
import { VC_DRAFT_KEY_PREFIX } from "./CredentialTypeDesigner";


function ClaimDeleteConfirmation({ claim, onCancel }) {

    const navigate = useNavigate();
    const { progress, notify } = useApp()

    const handleDelete = () => {
        progress(true);
        deleteCredentialType(claim.ref.id)
            .then(() => {
                notify("A credential type has been deleted.", "info");
                navigate(-1);
            })
            .catch((e) => notify(e, "error"))
            .then(() => progress(false))
    }

    return (
        <Toolbar sx={{ m: 0, p: 0, width: "100%" }} disableGutters>
            <ProtectedButton
                startIcon={<DeleteForever />}
                onClick={handleDelete}
                disabled={!claim}
                color="error"
                variant="contained"
                elevation={0}
            >
                Delete Forever
            </ProtectedButton>
            <Box sx={{ flexGrow: 1, }}></Box>
            <Button
                startIcon={<Close />}
                onClick={onCancel}
            >
                Cancel
            </Button>
        </Toolbar>
    );
}

function ClaimActions({ claim, onDelete }) {

    const navigate = useNavigate();

    const handleClone = () => {
        const cloneId = Date.now();
        const { ref, name = "", term = "", ...data } = claim;
        createDraft(VC_DRAFT_KEY_PREFIX + cloneId, {
            ...data,
            name: (name + " clone").trim(),
            term: (term + "Clone").trim(),
            modified: null,
        }, 0, 6);
        navigate(`/credential-type/draft/` + cloneId);
    };

    return (
        <Toolbar sx={{ p: 0, width: "100%" }} disableGutters>
            <ButtonLink color="secondary" startIcon={<ArrowBack />} to="/credential-type/">Directory</ButtonLink>
            <Button
                startIcon={<CopyAll />}
                onClick={handleClone}
                disabled={!claim}
            >
                Clone &amp; Design
            </Button>
            <ProtectedButton
                startIcon={<Edit />}
                disabled={!claim || true}
            >
                Edit [TODO]
            </ProtectedButton>
            <Button
                startIcon={<PlayArrow />}
                disabled={true}
            >
                Test [TODO]
            </Button>
            <Button
                startIcon={<Approval />}
                disabled={true}
            >
                Issue [TODO]
            </Button>                        
            <Box sx={{ flexGrow: 1, }}></Box>
            <ProtectedButton
                startIcon={<Delete />}
                onClick={onDelete}
                disabled={!claim}
                color="error"
            >
                Delete
            </ProtectedButton>
        </Toolbar>
    );
}

function SectionLabel({ children }) {
    return (
        <Typography
            gutterBottom
            sx={{
                fontSize: 15,
                fontWeight: 500,
                textTransform: "uppercase",
                fontFamily: 'Montserrat, sans-serif',
                color: "text.secondary",
                mb: 1,
            }}>
            {children}
        </Typography>
    );
}

export function CredentialTypeView({ }) {

    const [mode, setMode] = useState(null);

    const type = useLoaderData();

    const navigate = useNavigate();

    return (
        <Box>
            <Box>
                <PageTitle>
                    Credential Type: {type.toString()}
                </PageTitle>
                <PageSubtitle>
                    {type.description}
                </PageSubtitle>
            </Box>
            <VocabTermTableView mt={3} term={type.term} vocab={type.vocab} />
            <Box color={grey[50]} borderRadius={3} mr={2} mt={2}>
                {!mode && (<ClaimActions claim={type} onDelete={() => setMode("delete")} />)}
                {mode === "delete" && (<ClaimDeleteConfirmation claim={type} onCancel={() => setMode(null)} />)}
            </Box>
            <Box mt={3} maxWidth="sm">
                <SectionLabel>Subject</SectionLabel>
                <Box sx={{ mr: 2, ml: 1, mb: 1 }}>
                    <ClaimEntityView entity={type.subject} navigate={navigate} />
                </Box>
            </Box>
            <Box mt={3}>
                <SectionLabel>Claims</SectionLabel>
                <Box sx={{ mr: 2, ml: 1, mb: 1 }}>
                    <Claims claims={type.claims} navigate={navigate} />
                </Box>
            </Box>
            <Box mt={3}>
                <SectionLabel>History / Revisions</SectionLabel>
                <Box sx={{ mr: 2, ml: 1, mb: 1 }}>
                    TODO
                </Box>
            </Box>            
            {type.modified && (
                <Box mt={3}>
                    <Typography color="text.secondary" sx={{ fontSize: 12, }}>
                        Last modified: {moment(type.modified).format('lll')}
                    </Typography>
                </Box>
            )}
            <PageEnd />
        </Box>
    );
}

function Claims({ claims, navigate, depth = 0, index = [] }) {
    if (!claims || !Array.isArray(claims) || claims.length === 0) {
        return "No claims";
    }

    return claims.map((claim, i) => (
        <Box key={i}>
            <Box maxWidth="sm" mb={1}>
                {/* <ClaimEntityView entity={claim} navigate={navigage}/> */}
                <CardView
                    variant="simple"
                    badges={false}
                    index={([...index, i + 1].join(".") + ".")}
                    mandatory={claim.mandatory}
                    title={(depth === 0 ? "the subject" : ("the " + claim.subject.name)) + " " + claim.toString()
                        + (claim.object.isRel() ? " " + claim.object.toString() : "")
                    }
                    subtitle={
                        (claim.mandatory ? "mandatory " : "")
                        +
                        (claim.object.isAttribute() ? claim.object.toString().replace("value", "attribute") : "relation")

                    }
                    // description={claim.description}
                    // uri={claim.uri()}
                    onClick={() => navigate(`/claim/${claim.ref.id}`)}
                />
            </Box>
            {(claim.claims && Array.isArray(claim.claims) && claim.claims.length > 0) && (
                <Box ml={6} mb={1}>
                    <Claims claims={claim.claims} navigate={navigate} depth={depth + 1} index={[...index, i + 1]} />
                </Box>
            )}
        </Box>
    ));
}

function ClaimEntityView({ entity, navigate }) {

    if (!entity.ref?.path) {
        return (
            <CardViewStub
                badges={false}
                title={entity.toString()}
                description={entity.description}
            //                uri={entity.uri()}
            />
        );
    }

    return (<ClaimEnitityCheck entity={entity} navigate={navigate} />);
}

function ClaimEnitityCheck({ entity, navigate }) {

    const [record, setRecord] = useState(null);

    const { progress } = useApp();

    useEffect(() => {
        const get = async (path) => {
            try {
                progress(true);
                const doc = await getEntity(path);
                setRecord(doc);
                progress(false);

            } catch (e) {
                console.log(e);
            }
        }
        if (record === null) {
            get(entity.ref.path);
        }
    }, []);

    if (record === null) {
        return (
            <CardView
                badges={false}
                title={entity.toString()}
                description={entity.description}
            //            uri={entity.uri()}
            // onClick={() => navigate(`/entity/${entity.ref.id}`)}
            />
            // <Skeleton animation="wave" height={100} variant="rectangular" />
        );
    }

    if (!record?.exists()) {
        return (
            <CardViewStub
                badges={false}
                title={entity.toString()}
                description={entity.description}
            //                uri={entity.uri()}
            />
        );
    }

    const document = record.data();

    const newer = document?.modified && (!entity.modified
        || document.modified.getTime() > entity.modified.getTime());

    return (
        <CardView
            badges={false}
            title={entity.toString()}
            description={entity.description}
            //            uri={entity.uri()}
            onClick={() => navigate(`/entity/${entity.ref.id}`)}
            updateAvaiable={newer}
        />
    );
}