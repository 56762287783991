import { yupResolver } from '@hookform/resolvers/yup';
import { Email } from '@mui/icons-material';
import { Button, InputAdornment, Stack, TextField } from "@mui/material";
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { useApp } from 'lib/app';
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { PasswordField } from "../element/PasswordField";

const auth = getAuth();

const schema = yup.object({
    email: yup.string().trim().email().required(),
    password: yup.string().trim().min(10).required(),
}).required();

export function EmailPasswordLoginForm({
    disabled = false,
    onPasswordReset = () => { },
    onStateChange = () => { }
}) {

    const { control, setError, handleSubmit } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            email: "",
            password: "",
        }
    });

    const { progress } = useApp();

    const onSubmit = ({ email, password }) => {
        window.recaptchaVerifier.verify()
            .then(() => {
                progress();
                onStateChange("processing");
                signInWithEmailAndPassword(auth, email, password)
                    .then(() => {
                        onStateChange("idle");
                        progress(false);
                    })
                    .catch(() => {
                        setError("password", { type: "custom", message: "Access Denied" }, { shouldFocus: true })
                        onStateChange("error");
                        progress(false);
                    })
            })
            .catch(e => {
                setError("password", { type: "custom", message: "Your are a robot ;)" }, { shouldFocus: true })
            })
    }

    return (
        <form
            noValidate
            onSubmit={handleSubmit(onSubmit)}
        >
            <Stack spacing={1.5}>
                <Controller
                    name="email"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                        <TextField
                            label="Email"
                            fullWidth
                            required
                            variant='standard'
                            error={!!error}
                            InputProps={{
                                startAdornment: <InputAdornment position="start"><Email /></InputAdornment>,
                            }}
                            helperText={!!error && error.message}
                            disabled={disabled}
                            autoFocus
                            {...field}
                        />
                    )} />
                <Controller
                    name="password"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                        <PasswordField
                            label="Password"
                            fullWidth
                            required
                            variant='standard'
                            error={error}
                            disabled={disabled}
                            {...field}
                        />)}
                />
                <Button
                    variant="contained"
                    size="large"
                    fullWidth
                    disabled={disabled}
                    disableElevation
                    type="Submit"
                    sx={{
                        p: 1.5,
                        borderRadius: 3,
                    }}
                >
                    Sign In
                </Button>
                <Button
                    size="large"
                    fullWidth
                    disabled={disabled}
                    sx={{
                        p: 1.5,
                        borderRadius: 3,
                    }}
                    onClick={onPasswordReset}
                >Forgot password?</Button>
            </Stack>
        </form>
    )
}