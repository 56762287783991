import { getAnalytics } from "firebase/analytics";
import { initializeApp, getApps } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import { getAuth, RecaptchaVerifier } from "firebase/auth";

// Initialize Firebase
if (!getApps().length) {

  // Your web app's Firebase configuration
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  const firebaseConfig = {
    apiKey: "AIzaSyBiI4KvKEqI6UTa5lQ_FCjy9UnVOytC0gg",
    authDomain: "api-catalog-6bdcc.firebaseapp.com",
    projectId: "api-catalog",
    storageBucket: "api-catalog.appspot.com",
    messagingSenderId: "46531544000",
    appId: "1:46531544000:web:4cea6b6da104cc407afe25",
    measurementId: "G-K41MHD1WYG"
  };

  const app = initializeApp(firebaseConfig);

  if (process.env.NODE_ENV === "development") {
    console.log("running development environment")
    /*eslint-disable-next-line no-restricted-globals*/
    self.FIREBASE_APPCHECK_DEBUG_TOKEN = "f18c3fb7-3896-4906-a898-45252f16e830";
  }
  
  // Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
  // key is the counterpart to the secret key you set in the Firebase console.
  initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider("6Ldy7PkjAAAAAEiiRLYquClS8MiJ9TUF1OIB35pF"),

    // Optional argument. If true, the SDK automatically refreshes App Check
    // tokens as needed.
    isTokenAutoRefreshEnabled: true
  });

  // Initialize Cloud Firestore and get a reference to the service
  getFirestore(app);
  // Initialize Google Analytics
  getAnalytics(app);

  const auth = getAuth();

  window.recaptchaVerifier = new RecaptchaVerifier("recaptcha-container", { size: 'invisible' }, auth);
}