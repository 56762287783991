import { yupResolver } from '@hookform/resolvers/yup';
import { Email } from '@mui/icons-material';
import { Button, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import { useApp } from 'lib/app';
import { useState } from 'react';
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";

const auth = getAuth();
//z8CPRt4ENurpniJ
const schema = yup.object({
    email: yup.string().trim().email().required(),
}).required();

export function PasswordRecoveryForm({ onCancel, }) {

    const [state, setState] = useState("form");

    const { progress, } = useApp();

    const { control, handleSubmit, setError } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            email: "",
        }
    });

    const onSubmit = ({ email }) => {
        window.recaptchaVerifier.verify()
            .then(() => {
                progress()
                setState("processing");
                sendPasswordResetEmail(auth, email)
                    .then(() => {
                        setState("done");
                        progress(false);
                    })
                    .catch(() => {
                        setState("done");
                        progress(false);
                    })        
            })
            .catch(e => {
                setError("email", { type: "custom", message: "Your are a robot ;)" }, { shouldFocus: true })
            })
    }

    if (state === "done") {
        return (<>
            <Typography sx={{
                fontFamily: 'Montserrat, sans-serif',
            }}>
                An email with a link to reset your password has been sent to
                the provided email address. Please check your inbox.
            </Typography>
            <Button
                size="large"
                fullWidth
                sx={{
                    mt: 2,
                    p: 1.5,
                    borderRadius: 3,
                }}
                onClick={onCancel}
            >Back to Sign In</Button>
        </>
        )
    }

    return (
        <form
            noValidate
            onSubmit={handleSubmit(onSubmit)}
        >
            <Stack spacing={1.5}>
                <Controller
                    name="email"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                        <TextField
                            label="Email"
                            fullWidth
                            required
                            variant='standard'
                            error={!!error}
                            InputProps={{
                                startAdornment: <InputAdornment position="start"><Email /></InputAdornment>,
                            }}
                            helperText={!!error && error.message}
                            disabled={state === "processing"}
                            autoFocus
                            {...field}
                        />
                    )} />
                <Button
                    variant="contained"
                    size="large"
                    fullWidth
                    disableElevation
                    type="Submit"
                    disabled={state === "processing"}
                    sx={{
                        p: 1.5,
                        borderRadius: 3,
                    }}
                >
                    Request Reset Link
                </Button>
                <Button
                    size="large"
                    fullWidth
                    disabled={state === "processing"}
                    sx={{
                        p: 1.5,
                        borderRadius: 3,
                    }}
                    onClick={onCancel}
                >Back to Sign In</Button>
            </Stack>
        </form>
    )
}