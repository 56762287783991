import { Add, ArrowDropDown, Dashboard, Logout } from '@mui/icons-material';
import { AppBar as MuiAppBar, Avatar, Box, Button, IconButton, ListItemIcon, Menu, MenuItem, styled, Toolbar, Tooltip, Typography } from '@mui/material';
import { blueGrey, cyan, grey, lightBlue, teal } from '@mui/material/colors';
import ButtonLink from 'component/element/Link';
import { useSplashScreen } from 'component/element/SplashScreen';
import { useUser } from 'lib/user';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Offset = styled('div')(({ theme }) => theme.mixins.toolbar);

const TOP_LINKS = [
  {
    url: "/credential-type",
    label: "Credentials"
  },
  {
    url: "/claim",
    label: "Claims"
  },
  {
    url: "/entity",
    label: "Entities"
  },
]

export function Header({ }) {

  const navigate = useNavigate();

  return (<>
    <Box sx={{ flexGrow: 1 }} component="header">
      <MuiAppBar
        position="fixed"
        variant="outlined"
        elevation={0}
        sx={{ bgcolor: teal[500], color: grey[50] }}
      >
        <Toolbar variant="regular" sx={{ display: "flex", alignItems: "center" }}>
          <IconButton
            edge="start"
            aria-label="menu"
            // size="small"
            sx={{ color: "inherit", }}
            onClick={() => navigate("/")}
          >
            <Dashboard />
          </IconButton>

          <Typography
            sx={{
              fontWeight: 700,
              fontSize: 20,
              letterSpacing: 1,
              fontFamily: 'Montserrat, sans-serif',
              cursor: "pointer",
            }}
            component="div"
            color="inherit"
            onClick={() => navigate("/")}
            noWrap
          >
            Designer
          </Typography>

          <Box sx={{
            flexGrow: 1, justifyContent: "center", display: { xs: 'none', md: 'flex' },

          }}>
            {TOP_LINKS.map((topLink) => (
              <ButtonLink
                key={topLink.url}
                to={topLink.url}
                // onClick={handleCloseNavMenu}
                sx={{
                  color: grey[100], 
                  display: 'block', 
                  ml: 1, 
                  mr: 1,
                  fontFamily: 'Montserrat, sans-serif',
                  fontWeight: 600,
                  textTransform: "none",
                  fontSize: 16,
                }}
              >
                {topLink.label}
              </ButtonLink>
            ))}
          </Box>

          <Box sx={{ flexGrow: 1, }}></Box>
          <MenuAppBar mr={1} navigate={navigate} />
          <UserPanel mr={1} />

        </Toolbar>
      </MuiAppBar>
      <Offset />
    </Box>
  </>);
}


function UserPanel({ ...props }) {

  const [_, setScreen] = useSplashScreen();

  const { user, signout } = useUser();

  const [anchorEl, setAnchorEl] = useState(null);

  if (!user) {
    return (<Button color="inherit" onClick={() => setScreen("login")}>Login</Button>);
  }

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    setAnchorEl(null);
    signout();
  };

  return (<>
    <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }} {...props}>
      <Tooltip title="Account settings">
        <IconButton
          onClick={handleClick}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          size="small"
        >
          <Avatar alt={user.displayName} src={user.avatar} sx={{ width: 32, height: 32 }}></Avatar>
        </IconButton>
      </Tooltip>
    </Box>

    <Menu
      anchorEl={anchorEl}
      id="account-menu"
      open={open}
      onClose={handleClose}
      onClick={handleClose}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: 'visible',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          mt: 1.5,
          '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      <MenuItem onClick={handleLogout}>
        <ListItemIcon>
          <Logout fontSize="small" />
        </ListItemIcon>
        Logout
      </MenuItem>
    </Menu>
  </>)
}

function MenuAppBar({ navigate, ...params }) {

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSelect = (url) => {
    setAnchorEl(null);
    navigate(url);
  };

  return (<>
    <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }} {...params}>
      <IconButton
        onClick={handleClick}
        aria-controls={open ? 'create-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        size="small"
        sx={{ color: grey[200], }}
      >
        <Add size="small" sx={{ fontSize: 22 }} /><ArrowDropDown size="small" />
      </IconButton>
    </Box>

    <Menu
      anchorEl={anchorEl}
      id="create-menu"
      open={open}
      onClose={handleClose}
      onClick={handleClose}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: 'visible',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          mt: 1.5,
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      <MenuItem onClick={() => handleSelect("/credential-type/designer")}>
        <ListItemIcon>
          <Add fontSize="small" />
        </ListItemIcon>
        Credential Type
      </MenuItem>
      <MenuItem onClick={() => handleSelect("/claim/designer")}>
        <ListItemIcon>
          <Add fontSize="small" />
        </ListItemIcon>
        Claim
      </MenuItem>
      <MenuItem onClick={() => handleSelect("/entity/designer")}>
        <ListItemIcon>
          <Add fontSize="small" />
        </ListItemIcon>
        Entity
      </MenuItem>
    </Menu>
  </>);
}
