import { Dashboard } from '@mui/icons-material';
import { Box, Breadcrumbs, Container, Icon, IconButton, Typography } from '@mui/material';
import { amber, grey, teal } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';
import { alpha, ThemeProvider } from '@mui/system';
import { Protected, SplashScreen } from 'component/element/SplashScreen';
import { Header } from 'Header';
import { AppContextProvider } from 'lib/app';
import { UserProvider } from 'lib/user';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import build from "./build.json"

const theme = createTheme({
  palette: {
    primary: teal,
    secondary: {
      main: amber[800],
    }
  },
  typography: {
    h1: {
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 700,
    },
    h2: {
      fontFamily: 'Montserrat, sans-serif',
    },
    h3: {
      fontFamily: 'Montserrat, sans-serif',
    },
    h4: {
      fontFamily: 'Montserrat, sans-serif',
    },
    h5: {
      fontFamily: 'Montserrat, sans-serif',
    },
    h6: {
      fontFamily: 'Montserrat, sans-serif',
    },
  },
});

export function App({ children }) {
  return (
    <ThemeProvider theme={theme}>
      <AppContextProvider>
        <UserProvider>
          <Protected>
            {children}
          </Protected>
        </UserProvider>
      </AppContextProvider>
    </ThemeProvider>
  );
}

export function Main({ breadcrumbs = true, }) {
  return (<>
    <Header className="App-header" />
    <Box sx={{
      backgroundColor: alpha(grey[300], .5),
    }}>
      <Container maxWidth="lg" m={0} p={0} sx={{ backgroundColor: grey[50] }} disableGutters>
        {/* {breadcrumbs && (<ActionNav />)} */}
        <Container>
          <Box component="main"
            sx={{
              p: { xs: 1, sm: 2, md: 2 },
              mt: { xs: 0, sm: 0, md: 0, lg: 0 },
              mb: { xs: 2, sm: 4, md: 6, lg: 8 },
            }}>
            <Outlet />
          </Box>
        </Container>
        <Footer />
      </Container>
    </Box>
  </>);
}


function Footer() {
  return (
    <Box component="footer"
      pt={4} sx={{
        backgroundColor: alpha(grey[300], .5),
      }}>
      <Typography

        align="center"
        // color={red[900]}
        sx={{
          fontSize: 12,
          fontWeight: 400,
          letterSpacing: 0.25,
          fontFamily: 'Montserrat, sans-serif',
          textTransform: "uppercase",
          mt: 6,
          pb: 4,

        }}>
        {/* This is a demo. Use the service at your own risk. */}
        Build #{build.runId}
      </Typography>
    </Box>
  )
}

const mapping = {
  "/new-credential-type": "New Credential Type",
  "/new-claim-type": "New Claim Type",
}

function ActionNav() {

  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Breadcrumbs aria-label="breadcrumb" mt={2} ml={1} >
      <IconButton size="small" color="inherit" onClick={() => navigate("/")}>
        <Icon size="small" color="inherit"><Dashboard color="inherit" /></Icon>
      </IconButton>
      <Typography color="text.primary">{mapping[location.pathname]}</Typography>
    </Breadcrumbs>
  )
}

