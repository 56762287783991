import { Box, Grid, Typography } from '@mui/material';
import { useMemo, useRef, useState } from 'react';
import Code from './Code';
import ColoredStepper from './ColoredStepper';
import Examples from './Examples';
import { Main, MainHeader, PageSubtitle, Panel, PanelContent, Secondary, SecondaryHeader, SectionHeader } from './Layout';
import PrevNextButtons from './PrevNextButtons';

export function MultiFormStep({ label, description, onNext, onPrev, children, ...props }) {
  return (
    <Main {...props}>
      <MainHeader>{label}</MainHeader>
      <PanelContent>
        <Typography sx={{ fontFamily: 'Montserrat, sans-serif', }}>{description}</Typography>
        <Box mt={2}>
          {children}
        </Box>
      </PanelContent>
    </Main>
  );
}

function Step({
  value,
  step,
  onPrev,
  onNext,
  onChange,
}) {

  const [stepError, setStepError] = useState(false);

  const formRef = useRef(null);

  const Component = step.control;

  const stepValue = getStepValue(value, step.key, step.id);;

  const validate = (_value) => {
    const schema = step.schema || Component.Schema;

    if (!schema) {
      return true;
    }
    return schema.validate(_value, { abortEarly: false })
      .then(() => { setStepError(false); return true; })
      .catch(e => {

        let errors = e.message;

        if (Array.isArray(_value)) {

          errors = [...Array(_value.length)].fill(null);

          e.inner.filter(ei => ei.path.startsWith("[") && ei.path.endsWith("]")).forEach(ei => {
            const index = Number(ei.path.substring(1, ei.path.length - 1));
            errors[index] = ei.message;
          });

        } else if (step.key && Array.isArray(step.key) && step.key.length > 1) {

          errors = {};

          e.inner.forEach(e => {
            if (!errors[e.path]) {
              errors[e.path] = e.message;
            }
          });
        }

        setStepError(errors);
        return false
      });
  }
  
  const handleRemove = (_index) => {
    // hack
    if (Number.isInteger(_index) && Array.isArray(stepError)) {
        setStepError(se => se.filter((_, i) => i !== _index));
    }
  }

  const handleChange = (_value) => {
    const data = mergeStepValue(stepValue, _value);

    if (stepError) {
      validate(data);
    }

    onChange && onChange(step.key || step.id, data);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    const form = new FormData(e.target);

    let data = {};

    [...form.entries()].forEach(([k, v]) => replaceValue(data, k.split(/\.|\[(?=\d*)/g), v));

    if (!data.ref && !Component.Controlled) {  //FIXME hack, use Component.Controlled
      if (!step.key && Object.keys(data).length === 1 && data[step.id]) {
        data = data[step.id];
      }

      data = mergeStepValue(stepValue, data);

      if (Component.Type) {
        data = Object.assign(new Component.Type(), data);
      }

      //console.log("onSubmit", data);
      await validate(data) && onNext && onNext(step.key || step.id, data);

    } else {
      await validate(stepValue) && onNext && onNext(step.key || step.id, stepValue);
    }

    return false;
  }


  const defaultValue = useMemo(() => {
    return getStepValue(value, step.key, step.id);
  }, []);

  return (
    <MultiFormStep
      label={step.label}
      description={step.description}
    >
      <form onSubmit={handleSubmit} ref={formRef} noValidate method="POST">
        <Component
          defaultValue={defaultValue}
          data={value}
          error={stepError}
          onChange={handleChange}
          onSubmit={() => formRef.current.requestSubmit()}
          onRemove={handleRemove}
          {...(step.param || {})}
        />

        <PrevNextButtons
          onPrev={onPrev}
          mt={2}
          mb={1}
        />
      </form>
    </MultiFormStep>);
}

export function MultiForm({
  subtitle = null,
  defaultValue = {},
  steps,

  initialStep = 0,
  completed,

  DocumentType = {},
  EndComponent,
  PreviewComponent,

  simple = false,

  onChange = () => { },
  onComplete = () => { },
  onCancel = () => { },
}) {

  const [{ activeStep, value, mode }, setState] = useState({
    activeStep: initialStep >= 0 ? initialStep : 0,
    value: defaultValue,
    mode: "edit"
  });

  const handleComplete = () => {
    setState(p => ({ ...p, mode: "complete" }));
    onComplete(value)
  }

  const handleNext = (key, _value) => {
    const data = merge(key, _value, DocumentType)(value);
    onChange && onChange(activeStep + 1, data);
    setState(p => ({ ...p, value: data, activeStep: activeStep + 1 }));
  }

  const handleChange = (key, _value) => {
    setState(p => ({ ...p, value: merge(key, _value, DocumentType)(value) }));
  }

  const handlePrev = () => {

    if (activeStep === 0) {
      onCancel(value);

    } else {
      onChange && onChange(activeStep - 1, value);
      setState(p => ({ ...p, activeStep: activeStep - 1 }));
    }
  };

  const step = steps[activeStep];

  return (
    <Box p={{ xs: 0, sm: 0 }} mt={{ xs: 1, sm: 1, md: 2 }}>
      {subtitle && (
        <PageSubtitle>
          {subtitle}
        </PageSubtitle>
      )}
      <Box
        pt={{ xs: 3, sm: 3, md: 5, lg: 5 }} pb={{ xs: 4, sm: 4, md: 5, lg: 5 }}
        mb={2}
      >
        <ColoredStepper steps={steps} active={activeStep} completed={completed} />
      </Box>

      {simple ? (<Box justifyContent="center" display="flex">

        {activeStep < steps.length ? (<Box maxWidth="sm" flexGrow={1} >
          <Step
            key={step.id}
            value={value}
            step={step}
            onPrev={(!!onCancel || activeStep > 0) ? handlePrev : null}
            onNext={handleNext}
            onChange={handleChange}
          />
        </Box>) :
          !!EndComponent && (<Box maxWidth="sm" flexGrow={1}>
            <EndComponent value={value} onPrev={handlePrev} onNext={handleComplete} mode={mode} />
          </Box>)
        }

      </Box>) : (
        <Grid container spacing={{ xs: 1, sm: 2, md: 2 }}>
          <Grid item xs={12} md={7} lg={7}>


            {activeStep < steps.length ? (<>
              <Step
                key={step.id}
                value={value}
                step={step}
                onPrev={(!!onCancel || activeStep > 0) ? handlePrev : null}
                onNext={handleNext}
                onChange={handleChange}
              />
              {(step.help) && (
                <Panel mt={{ xs: 2, sm: 2, md: 4 }}>
                  <SectionHeader>Guidelines</SectionHeader>
                  <PanelContent>
                    {step.help}
                  </PanelContent>
                </Panel>
              )}
            </>) :
              !!EndComponent && (
                <EndComponent value={value} onPrev={handlePrev} onNext={handleComplete} mode={mode} />
              )
            }

          </Grid>
          <Grid item xs={12} md={5} lg={5}>
            {!!PreviewComponent && (<Panel>
              <SectionHeader>Preview</SectionHeader>
              <PreviewComponent value={value} />
            </Panel>)}

            {(activeStep < steps.length
              && step.detail
              && (!step.detail.filter
                || step.detail.filter(value[step.id])
              )) && (<StepDetail
                Component={step.detail.Component}
                label={step.detail.label}
                value={value[step.id]}
              />)}

            {(activeStep < steps.length && step.examples && step.examples.length > 0) && (
              <Examples mt={{ xs: 2, sm: 2, md: 4 }}>
                {step.examples[0]} {/*TODO use all examples the array somehow */}
              </Examples>
            )}
          </Grid>
        </Grid>
      )}
      {/* <Code value={value} mediaType="json" /> */}
    </Box>
  );
}

function mergeStepValue(stepValue, value) {
  let data = value || null;

  if ((Array.isArray(value) && value.length === 0)
    || (typeof data === "object" && Object.keys(value).length === 0)
  ) {
    data = null;
  }

  if (Array.isArray(stepValue) || Array.isArray(value)) {
    return data;
  }

  if (stepValue && data && typeof stepValue === "object" && typeof data === "object") {
    data = Object.assign(stepValue, value);
  }
  return data;
}

function merge(key, value, DocumentType) {

  if (!key || Array.isArray(key)) {
    return (t) => Object.assign(new DocumentType(), t, value);
  }

  return (t) => Object.assign(new DocumentType(), t, { [key]: value });
}

function StepDetail({ Component, label = "Detail", value }) {
  return (
    <Secondary mt={2}>
      <SecondaryHeader>{label}</SecondaryHeader>
      <Box ml={1}>
        <Component value={value} />
      </Box>
    </Secondary>);
}


function getStepValue(data, key, id) {
  return !key
    ? data[id]
    : Object.keys(data)
      .filter((k) => key.includes(k))
      .reduce((c, k) => {
        c[k] = data[k];
        return c;
      }, {});
}

function replaceValue(target, path, value) {
  if (!path || path.length === 0) {
    return value;
  }

  if (path.length === 1) {

    let name = path[0];
    let result = target;

    if (name.endsWith("]")) {
      //      name = name.substring(0, name.length - 1);
      // if (!value || value === null /*|| (typeof value === "string" && value.trim().length === 0)*/) {
      //   return result;
      // }

      if (!result) {
        result = [];
      }
      result.push(value);
      return result;
    }

    if (!result) {
      result = {};
    }

    result[name] = value;

    return result;
  }

  const [index, ...rest] = path;

  target[index] = replaceValue(target[index], rest, value);
  return target
}
