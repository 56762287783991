import { Box, Button } from "@mui/material"
import { Link as RouterLink, useNavigate } from "react-router-dom";


export default function ButtonLink({ to, href, children, ...props }) {

    const navigate = useNavigate();

    return (
        <Button onClick={() => navigate(to || href)} disableElevation sx={{ whiteSpace: "nowrap" }}  {...props}>
            {children}
        </Button>
    );
}

export function Link({ to, href, children, ...props }) {
    return (
        <Box {...props}>
            <RouterLink to={to} >{children}</RouterLink>
        </Box>
    )
}